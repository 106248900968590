import { FileCopyOutlined, PersonOutlined, WhatsappOutlined } from "@mui/icons-material";
import { Box, Typography, IconButton } from "@mui/material";
import { get, isEmpty } from "lodash";
import { useMemo } from "react";
import copy from 'copy-to-clipboard';

const Invite = ({ invite, ...rest }) => {

    const invitationLink = useMemo(_ => {
        if (!isEmpty(invite)) {
            return `${window.location.origin}/guest/${invite.id}`
        }
    }, [invite]);

    const handleCopyLink = _ => {
        copy(invitationLink);
    }

    const handleShareViaWhatsapp = _ => {
        const { phone } = invite;
        const url = `https://api.whatsapp.com/send/?phone=${phone}&text=${invitationLink}`;
        window.open(url, '_blank');
    }

    return (
        <Box
            {...rest}
            sx={{
                display: 'flex',
                borderRadius: 2,
                border: '1px solid #A3BCCF',
                backgroundColor: "#F7F7FA",
                width: '100%',
                my: 6,
                flexDirection: 'row',
                justifyContent: 'space-between',
                p: '8px',
                ...get(rest, 'sx')
            }}
        >
            <Box sx={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                <PersonOutlined sx={{ fontSize: 22 }} />
                <Typography
                    sx={{
                        font: "bold 18px/22px Rabar",
                        // alignSelf: "flex-start",
                        marginInlineStart: 4
                    }}
                >
                    {invite.name}
                </Typography>
            </Box>
            <Box
                sx={{
                    alignSelf: "flex-end"
                }}
            >
                {
                    get(invite, 'phone') &&
                    <IconButton
                        color="primary"
                        onClick={handleShareViaWhatsapp}
                        sx={{p:0}}
                    >
                        <WhatsappOutlined sx={{ fontSize: 24 }} />
                    </IconButton>
                }
                <IconButton
                    color="primary"
                    onClick={handleCopyLink}
                >
                    <FileCopyOutlined sx={{ fontSize: 24 }} />
                </IconButton>
            </Box>
        </Box>
    )
}

export default Invite;