import { Alert, Box, Button, Container, IconButton, Snackbar, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import AdminService from "services/AdminServices"
import DataTable from "modules/_shared/DataTable";
import { useTranslation } from "react-i18next";
import InvitationDialog from "../components/invitations/InvitationDialog"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { DATE_FORMATS } from "constants";
import { FormatDate } from "utils";
import { get, mapValues } from "lodash";
import copy from 'copy-to-clipboard';

const $COLUMN_WIDTH = 190;


export default function Invitations() {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [invitations, setInvitations] = useState([])
    const [tableOptions, setTableOptions] = useState({
        rowCount: 20,
        page: 1,
        pageSize: 20
    })

    const tooltipMessages = {
        copyLink: { original: "Copy Link", onClick: "Link Copied" },
        shareUsingWhatsapp: { original: "Share using Whatsapp", onClick: "Share using Whatsapp" }
    }

    const [tooltipDisplayedMessages, setTooltipDisplayedMessages] = useState(
        mapValues(tooltipMessages, message => message.original)
    );

    const handleCloseTooltip = type => {
        setTooltipDisplayedMessages((prevState) => ({ ...prevState, [type]: get(tooltipMessages, `${type}.original`) }))
    };

    const navigateThroughLoadedData = (page) => {
        setTableOptions((prevState) => ({
            ...prevState,
            page: page,
        }))
    }

    const clearData = () => {
        setInvitations([])
        setTableOptions({
            rowCount: 20,
            page: 1,
            pageSize: 20
        })
    }

    const getInvitations = async (page = 1) => {
        try {
            setLoading(true)
            let response = await AdminService.getInvitations(page)
            let data = response.body
            setInvitations(prevState => ([
                ...prevState,
                ...data.docs
            ]))
            setTableOptions((prevState) => ({
                ...prevState,
                rowCount: data.totalDocs,
                page: data.page,
            }))
        } catch (error) {
            console.log("error")
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const shareInvitationViaWhatsapp = (e, invitation) => {
        e.stopPropagation()
        const { phone, id } = invitation;
        const url = `https://api.whatsapp.com/send/?phone=${phone}&text=${window.location.origin}/guest/${id}`;
        window.open(url);
    }

    const getDayDate = params => (params.row.date ? <FormatDate date={params.row.date} format={DATE_FORMATS.dayMonthYear} /> : '-')

    const renderActionCell = params => (
        <Box>
            <Tooltip placement="top" onClose={handleCloseTooltip} title={t(get(tooltipDisplayedMessages, 'copyLink'))}>
                <IconButton key={params.row.id} onClick={(e) => {
                    e.stopPropagation()
                    setTooltipDisplayedMessages(prevState => ({ ...prevState, "copyLink": get(tooltipMessages, 'copyLink.onClick') }))
                    copy(`${window.location.origin}/guest/${params.row.id}`);
                }}>
                    <ContentCopyIcon />
                </IconButton>
            </Tooltip>
            <Tooltip placement="top" onClose={handleCloseTooltip} title={t(get(tooltipDisplayedMessages, 'shareUsingWhatsapp'))}>
                <IconButton
                    disabled={!params.row.phone}
                    key={params.row.id}
                    onClick={e => shareInvitationViaWhatsapp(e, params.row)}
                >
                    <WhatsAppIcon />
                </IconButton>
            </Tooltip>
        </Box>
    );

    const renderPhoneCell = params => (
        <Box dir="ltr" className="MuiDataGrid-cellContent">
            {params.value}
        </Box>
    )

    const columns = [
        { field: "uniqueCode", headerName: t("Invitation Code"), width: $COLUMN_WIDTH * 0.7, },
        { field: "name", headerName: t("Name"), width: $COLUMN_WIDTH * 1.3, },
        { field: "status", headerName: t("Status"), valueGetter: params => t(params.row.status), width: $COLUMN_WIDTH * 0.8 },
        { field: "type", headerName: t("Type"), valueGetter: params => t(params.row.type), width: $COLUMN_WIDTH * 1, },
        { field: "phone", headerName: t("Phone"), renderCell: renderPhoneCell, width: $COLUMN_WIDTH * 0.7, },
        { field: "guests", headerName: t("Guests"), width: $COLUMN_WIDTH * 0.4, },
        { field: "date", headerName: t("Invitation Date"), renderCell: getDayDate, width: $COLUMN_WIDTH * 0.8, },
        { field: "actions", headerName: t(""), width: $COLUMN_WIDTH * 0.5, renderCell: renderActionCell },
    ];

    useEffect(() => {
        getInvitations()
        return () => clearData()
    }, [])

    // ========snackbar=================
    const [snackbarOptions, setSnackbarOptions] = useState({
        open: false,
        message: "",
        status: "success"
    })

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOptions(prevState => ({
            ...prevState,
            open: false,
            message: "",
        }));
    };

    //============Dialog operations============
    const [openInvitation, setOpenInvitation] = useState(false)
    const [selectedInvitation, setSelectedInvitation] = useState(null)

    const openInvitationDialog = () => {
        setOpenInvitation(true)
    }
    const closeInvitationDialog = () => {
        setOpenInvitation(false)
        setSelectedInvitation(null)
    }
    //==============Request Opertion=============
    const [requestLoading, setRequestLoading] = useState(false)
    const deleteInvitationHandler = async (id) => {
        try {
            setRequestLoading(true)
            await AdminService.deleteInvitation(id)
            let data = [...invitations]
            let index = data.findIndex(invitation => invitation.id === id)
            data.splice(index, 1)
            setInvitations([...data])
            setTableOptions((prevState) => ({
                ...prevState,
                rowCount: prevState.rowCount - 1
            }))
            setSnackbarOptions({
                open: true,
                message: t("ActionSuccessMessage", { item: t("invitation"), action: t("deleted") }),
                status: 'success'
            })
        } catch (error) {
            console.log("error")
            console.log(error)
            setSnackbarOptions({
                open: true,
                message: t(error.response.data.message),
                status: 'error'
            })
        } finally {
            setRequestLoading(false)
        }
    }

    const createInvitationHandler = async (payload) => {
        try {
            setRequestLoading(true)
            let response = await AdminService.createInvitation(payload)
            setInvitations((prevState) => [{ ...response?.body }, ...prevState])
            setTableOptions((prevState) => ({
                ...prevState,
                rowCount: prevState.rowCount + 1
            }))
            setSnackbarOptions({
                open: true,
                message: t("ActionSuccessMessage", { item: t("invitation"), action: t("created") }),
                status: 'success'
            })
            closeInvitationDialog();
        } catch (error) {
            console.log("error")
            console.log(error)
            setSnackbarOptions({
                open: true,
                message: t(error.response.data.message),
                status: 'error'
            })
        } finally {
            setRequestLoading(false)
        }
    }

    const updateInvitationHandler = async (id, payload) => {
        try {
            setRequestLoading(true)
            let response = await AdminService.updateInvitation(id, payload)
            let data = [...invitations]
            let index = data.findIndex(invitation => invitation.id === id)
            data[index] = { ...response.body }
            setInvitations([...data])
            setSnackbarOptions({
                open: true,
                message: t("ActionSuccessMessage", { item: t("invitation"), action: t("updated") }),
                status: 'success'
            })
            closeInvitationDialog();
        } catch (error) {
            console.log("error")
            console.log(error)
            setSnackbarOptions({
                open: true,
                message: t(error.response.data.message),
                status: 'error'
            })
        } finally {
            setRequestLoading(false)
        }
    }


    return (
        <Container sx={{ marginTop: 20 }}>
            <InvitationDialog
                selectedInvitation={selectedInvitation}
                loading={requestLoading}
                openInvitation={openInvitation}
                closeInvitationDialog={closeInvitationDialog}
                deleteInvitationHandler={deleteInvitationHandler}
                createInvitationHandler={createInvitationHandler}
                updateInvitationHandler={updateInvitationHandler}
            />
            <Box display="flex" justifyContent="end">
                <Button sx={{ textTransform: "none" }} onClick={openInvitationDialog} variant="contained">{t("Add")}</Button>
            </Box>
            <Box>
                <DataTable
                    color='secondary'
                    list={invitations}
                    columns={columns}
                    loading={loading}
                    rowCount={tableOptions.rowCount}
                    page={tableOptions.page}
                    pageSize={tableOptions.pageSize}
                    loadMoreData={getInvitations}
                    handleRowClick={({ row }) => {
                        setSelectedInvitation({ ...row })
                        openInvitationDialog()
                    }}
                    navigateThroughLoadedData={navigateThroughLoadedData}
                    checkbox={false}
                />
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOptions.open}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    // onClose={handleSnackbarClose}
                    severity={snackbarOptions.status} sx={{ width: '100%' }}>
                    {t(snackbarOptions.message)}
                </Alert>
            </Snackbar>
        </Container>
    )
}