import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
// import { Link as RouterLink } from "react-router-dom";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import PropTypes from 'prop-types';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LogoWhite, SicileeArabicLogoWhite } from 'assets/images';
import { I18N_LANGUAGES } from "constants";
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'context';
import { useNavigate } from "react-router-dom";

export default function ButtonAppBar({ handleDrawerOpen }) {

  const navigate = useNavigate()
  const { authState: { isAuth, role }, logout, setCurrentLanguage } = React.useContext(AuthContext);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { t, i18n } = useTranslation();

  const handleLanguageClick = _ => {
    const language = i18n.language
    const languageToSet = language === I18N_LANGUAGES.ar ? I18N_LANGUAGES.en : I18N_LANGUAGES.ar
    i18n.changeLanguage(languageToSet);
    setCurrentLanguage(languageToSet)
    window.location.reload();
  };
  function ElevationScroll(props) {
    const { children } = props;
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
    });

    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
  }

  ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
  };
  return (
    <ElevationScroll>
      <AppBar color="primary" position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Box px={15}>
          <Toolbar>
            {matches && role === "admin" && <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>}
            {
              isAuth && role === "admin" &&
              <img alt='Logo' style={{cursor: "pointer"}} onClick={_ => navigate("/")} src={theme.direction === "rtl" ? SicileeArabicLogoWhite : LogoWhite} height={35} width={70} />
            }
            <Box flexGrow={1}></Box>
            {isAuth && <Button sx={{ textTransform: "none" }} onClick={logout} color="inherit">{t("Logout")}</Button>}
            <Button
              color="inherit"
              disableRipple
              py={0}
              sx={{ textTransform: "none" }}
              onClick={handleLanguageClick}
            >
              {t("ع")}
            </Button>
          </Toolbar>
        </Box>
      </AppBar>
    </ElevationScroll>
  );
}
