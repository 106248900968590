import { useTheme } from '@emotion/react';
import { SmallLogoBlue } from 'assets/images';
import { SicileeArabicLogo } from 'assets/images';
import { useNavigate } from 'react-router-dom';

const SicilyLogo = ({ ...props }) => {
    const theme = useTheme()
    const navigate = useNavigate()
    return (
        <img
            onClick={() => navigate("/")}
            alt="logo"
            src={theme.direction === "rtl" ? SicileeArabicLogo : SmallLogoBlue}
            {...props}
        />
    )
}

export default SicilyLogo;