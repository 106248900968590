import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SicilyLogo } from "modules/_shared"

const InvalidInvitation = () => {
    const { t } = useTranslation()

    return (
        <Box sx={{ textAlign: "center" }} >
            <SicilyLogo style={{ marginTop: 100, height: 100, cursor: "pointer" }} />
            <Typography color="primary" variant="h1" sx={{ fontWeight: 'bold' }}>{t("This invitation does not exist")}</Typography>
        </Box>
    )
}

export default InvalidInvitation;