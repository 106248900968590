import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Wrong, Right } from 'assets/icons';
import { Icon } from 'modules/_shared';
import { DATE_FORMATS } from "constants";
import FormatDate from 'utils/FormatDate';
import { get, includes } from 'lodash';
import PropTypes from "prop-types";
import { INVITATION_STATUS } from 'constants';
import { useMemo } from 'react';

const InvitationCheckInResult = ({ type, failReason, invitation, onCheckInOthers }) => {

    const { t } = useTranslation()

    const iconSource = useMemo(_ => {
        switch (type) {
            case "success":
                return Right;
            case "error":
                return Wrong;
            default:
                return
        }
    }, [type])

    return (
        <Box sx={{ textAlign: "center" }}>
            {
                iconSource &&
                <Icon svgSource={iconSource} height={100} width={100} />
            }
            <Box mt={24}>
                {
                    type === "success" &&
                    <>
                        <Typography color="primary" sx={{ font: "normal 36px/36px Rabar" }}>
                            {t("Checked in")}
                        </Typography>
                        <Typography color="primary" sx={{ font: "bold 36px/36px Rabar" }}>
                            {t("Successfully")}
                        </Typography>
                    </>
                }
                {
                    type === "error" &&
                    <Typography color="primary" sx={{ font: "normal 20px/25px Rabar" }}>
                        {t("Sorry!")}
                    </Typography>
                }
                {
                    failReason === INVITATION_STATUS.checkedIn &&
                    <Typography color="primary" sx={{ font: "bold 20px/25px Rabar", whiteSpace: 'pre-wrap' }}>
                        {`${t(`This invitation already checked in`)}\n${t("on")} `}
                        <FormatDate
                            date={get(invitation, 'checkInDate')}
                            format={DATE_FORMATS.dayMonthYearTime}
                        />
                    </Typography>
                }
                {
                    failReason === INVITATION_STATUS.expired &&
                    <Typography color="primary" sx={{ font: "bold 20px/25px Rabar", whiteSpace: 'pre-wrap' }}>
                        {t("This invitation date has expired")}
                    </Typography>
                }
                {
                    failReason === "NOT_FOUND" &&
                    <Typography color="primary" sx={{ font: "bold 20px/25px Rabar", whiteSpace: 'pre-wrap' }}>
                        {t("Invitation not found")}
                    </Typography>
                }
            </Box>
            <Button
                onClick={onCheckInOthers}
                variant='contained'
                sx={{
                    mt: 30,
                    backgroundColor: "#ED8B35",
                    width: 327,
                    height: 55,
                    borderRadius: 27.5,
                    textTransform: "none",
                    font: 'bold 19px/23px Rabar'
                }}
            >{t("Check In new guests")}
            </Button>
        </Box>
    )
}

InvitationCheckInResult.propTypes = {
    type: PropTypes.oneOf(["success", "error"]).isRequired,
    failReason: function (props, propName, componentName) {
        const acceptableReasons = [INVITATION_STATUS.expired, INVITATION_STATUS.checkedIn, "NOT_FOUND"];
        if (get(props, 'type') === "error" && !includes(acceptableReasons, get(props, propName))) {
            return new Error(
                'Invalid prop `' + propName + '` supplied to' +
                ' `' + componentName + '`. Expected one of: ' + JSON.stringify(acceptableReasons)
            );
        }
    },
    invitation: PropTypes.object,
    onCheckInOthers: PropTypes.func.isRequired,
};

export default InvitationCheckInResult;