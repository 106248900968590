import http from "services/httpService";
import { $BASE_URL } from "constants";

const apiUrl = $BASE_URL + '/api';

const apiEndpoints = apiUrl + "/v1/admin-panel"

//=============== Admin Invitations CRUD=======================
async function getInvitations(page){
  const response = await http.get(`${apiEndpoints}/invitation?page=${page}`);
  return response.data;
}
async function getInvitationById(id){
  const response = await http.get(`${apiEndpoints}/invitation/${id}`);
  return response.data;
}
async function createInvitation(payload){
  const response = await http.post(`${apiEndpoints}/invitation`, payload);
  return response.data;
}
async function updateInvitation(id, payload){
  const response = await http.put(`${apiEndpoints}/invitation/${id}`, payload);
  return response.data;
}
async function deleteInvitation(id){
  const response = await http.delete(`${apiEndpoints}/invitation/${id}`);
  return response.data;
}
//=============== Admin Hosts CRUD=======================

 async function getHosts(page) {
  const response = await http.get(`${apiEndpoints}/host/?page=${page}`);
  return response.data;
}
async function getHostById(id){
  const response = await http.get(`${apiEndpoints}/Host/${id}`);
  return response.data;
}
async function createHost(payload){
  const response = await http.post(`${apiEndpoints}/Host/create`, payload);
  return response.data;
}
async function updateHost(id, payload){
  const response = await http.put(`${apiEndpoints}/Host/${id}`, payload);
  return response.data;
}
async function deleteHost(id){
  const response = await http.delete(`${apiEndpoints}/Host/${id}`);
  return response.data;
}


const AdminService = {
  getInvitations,
  getInvitationById,
  createInvitation,
  updateInvitation,
  deleteInvitation,
  getHosts,
  getHostById,
  createHost,
  updateHost,
  deleteHost
};

export default AdminService;
