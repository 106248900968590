import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Container, Rating } from '@mui/material';
import { LogoBlue } from 'assets/images';
import { useTranslation } from 'react-i18next';
import GradeIcon from '@mui/icons-material/Grade';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const steps = [
    {
        label: 'Select campaign settings',
        description: `For each ad campaign that you create, you can control how much
              you're willing to spend on clicks and conversions, which networks
              and geographical locations you want your ads to show on, and more.`,
    },
    {
        label: 'Create an ad group',
        description:
            'An ad group contains one or more ads which target a shared set of keywords.',
    },
    {
        label: 'Create an ad',
        description: `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`,
    },
];

export default function TextMobileStepper() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false)
    const [done, setDone] = useState(false)
    const maxSteps = steps.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const submitRatingForm = async () => {
        try {
            setLoading(true)
            await new Promise(resolve => setTimeout(resolve, 1000));
            setDone(true)
            setTimeout(()=>{
                navigate('/')
            }, 2500)
        } catch (error) {
            console.log('error')
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Container>
            <Box display="flex" flexDirection='column' height='100vh' justifyContent="space-between">
                <Box display={"flex"} justifyContent={"center"}>
                    <img
                        alt="sicilee logo"
                        style={{
                            width: 300,
                            height: 200,
                        }}
                        src={LogoBlue}
                    />
                </Box>
                <Box display='flex' flexDirection='column' flexGrow={1} alignItems='center' justifyContent='center'>
                    {!done ?
                        <>
                            <Box p={10}>
                                <Typography variant='h5' fontWeight={700}>{steps[activeStep].label}</Typography>
                            </Box>
                            <Box sx={{ width: '100%', p: 2, textAlign: 'center', my: 100 }}>
                                {/* {steps[activeStep].description} */}
                                <Rating sx={{ fontSize: '3.875rem' }} name="size-large" defaultValue={2} size="large" />
                            </Box>
                        </>
                        :
                        <>
                            <GradeIcon sx={{ fontSize: 250 }} color="secondary" />
                            <Typography variant='h4' color="primary">{t("Thank you!")}</Typography>
                            <Typography color='secondary'>{t("we appreciate your help")}</Typography>
                        </>}

                </Box>
                <MobileStepper
                    variant="text"
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        activeStep !== maxSteps - 1 ?
                            <Button
                                size="small"
                                onClick={handleNext}
                                disabled={activeStep === maxSteps - 1}
                            >
                                {t('Next')}
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowLeft />
                                ) : (
                                    <KeyboardArrowRight />
                                )}
                            </Button>
                            :
                            <LoadingButton onClick={submitRatingForm} loading={loading} size="small">{t('Submit')}</LoadingButton>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                            )}
                            {t('Back')}
                        </Button>
                    }
                />
            </Box>
        </Container>
    );
}
