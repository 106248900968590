import { LoadingButton } from "@mui/lab";
import html2canvas from "html2canvas";
import InvitationDetails from "modules/guest/InvitationDetails";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DownloadIcon from '@mui/icons-material/Download';

export default function DownloadInvitationAsImage({ invitation }) {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)

    const downloadImageHandler = async () => {
        setLoading(true)
        const element = document.getElementById(`invitation-${invitation.id}`);
        const canvas = await html2canvas(element, {
            useCORS: true,
            allowTaint: true,
            scrollY: -window.scrollY,
            onclone: function (clonedDoc) {
                clonedDoc.getElementById(`invitation-${invitation.id}`).style.display = 'block';
            }
        })
        const data = canvas.toDataURL("image/png", 1.0);
        const link = document.createElement('a');

        if (typeof link.download === 'string') {
            link.href = data;
            link.download = 'image.png';

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            window.open(data);
        }
        setLoading(false)
    }
    return (
        <>
            <LoadingButton
                startIcon={<DownloadIcon />}
                onClick={downloadImageHandler}
                loading={loading}
                size="small"
                sx={{ textTransform: "none" }}>
                {t("Download the invitation as an image")}
            </LoadingButton>
            <InvitationDetails display="none" invitation={invitation} id={`invitation-${invitation.id}`} />
        </>
    )
}