import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Divider, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select, TextField, Typography, Checkbox, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MapItLocalizedNumberTextField from 'modules/_shared/MapItLocalizedNumberTextField';
import MapitPhoneInput from 'modules/_shared/MapitPhoneInput';
import ClearIcon from '@mui/icons-material/Clear';
import ConfirmationDialog from 'modules/_shared/ConfirmationDialog';
import { invitationValidationSchema } from './validationSchema';
import { runJoiValidate } from "utils";
import { cloneDeep, get, includes, pick } from 'lodash';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LoadingButton } from '@mui/lab';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { INVITATION_TYPES, GENDERS, INVITATION_STATUS, DATE_FORMATS } from 'constants';
import { Box } from '@mui/system';
import moment from "moment"
import 'moment/locale/ar';
import DownloadInvitationAsImage from './DownloadInvitationAsImage';
moment.locale(localStorage.getItem("i18nextLng"));
// qrCode: "n2uzo6fkpnczmg8oiged.png"

export default function ResponsiveDialog({
    loading,
    selectedInvitation,
    openInvitation,
    closeInvitationDialog,
    createInvitationHandler,
    updateInvitationHandler,
    deleteInvitationHandler,
}) {
    const { t } = useTranslation()
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isEditing = !!selectedInvitation;
    const [invitation, setInvitation] = useState({
        name: "",
        label_en: "Dear Brother",
        label_ar: "الأخ الكريم",
        description_en: "We are pleased to invite you to the opening of Sicilee Restaurant and to experience distinctive dishes of Italian cuisine",
        description_ar: "يسرنا دعوتكم الى إفتتاح مطعم سيسلي وتجربة أطباق مميزة من المطبخ الإيطالي",
        gender: GENDERS.male,
        guests: 1,
        type: INVITATION_TYPES.opening,
        date: null,
        from: null,
        to: null
    })

    const [errors, setErrors] = useState(null)

    const invitationChangeHandler = ({ target: { name, value, checked } }) => {
        if (["from", "to", "date"].includes(name) && !!value) {
            value = new Date(value).toISOString()
        } else if (["from", "to", "date"].includes(name)) {
            value = null
        }
        if (name === "guests") {
            value = +value
        }
        if (name === "canSend") {
            value = checked
        }
        setInvitation((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const resetInvitation = () => {
        setErrors(null)
        setInvitation({
            name: "",
            label_en: "Dear Brother",
            label_ar: "الأخ الكريم",
            description_en: "We are pleased to invite you to the opening of Sicilee Restaurant and to experience distinctive dishes of Italian cuisine",
            description_ar: "يسرنا دعوتكم الى إفتتاح مطعم سيسلي وتجربة أطباق مميزة من المطبخ الإيطالي",
            gender: GENDERS.male,
            phone: "",
            guests: 1,
            type: INVITATION_TYPES.opening,
            date: null,
            from: null,
            to: null
        })
    }

    useEffect(_ => {
        if (isEditing) return
        if (invitation.gender === GENDERS.female & invitation.canSend === undefined) {
            console.log("setting canSend for the first time")
            setInvitation((prevState) => ({
                ...prevState,
                canSend: true
            }))
        }

    }, [invitation, isEditing])


    const invitationGenderHandler = (event) => {
        let value = event?.target?.value
        if (value === GENDERS.female) {
            setInvitation((prevState) => ({
                ...prevState,
                gender: value,
                label_en: "Dear Sister",
                label_ar: "الأخت الكريمة"
            }))
        } else if (value === GENDERS.male) {
            setInvitation((prevState) => ({
                ...prevState,
                gender: value,
                label_en: "Dear Brother",
                label_ar: "الأخ الكريم",
            }))
        }
    }

    const dialogCloseHandler = () => {
        closeInvitationDialog()
        resetInvitation()
    }

    const submitHandler = async (e) => {
        e.preventDefault()
        setErrors(null)
        let paramsToPick = ["date", "name", "label_en", "label_ar", "description_en", "description_ar", "gender", "phone", "guests", "type", "from", "to"];
        if (get(invitation, 'gender') === GENDERS.female) {
            paramsToPick.push("canSend")
        }
        let payload = pick(
            cloneDeep(invitation),
            paramsToPick
        );
        const validationErrors = runJoiValidate(invitationValidationSchema, payload);
        if (!!payload.from !== !!payload.to) {
            setErrors({ from: t("If you enter this field, you must enter the other field"), to: t("If you enter this field, you must enter the other field") })
            return
        }
        if (validationErrors) {
            setErrors((prev) => ({ ...prev, ...validationErrors }))
            return
        }
        if (isEditing) {
            await updateInvitationHandler(invitation.id, payload)
            // dialogCloseHandler()
        } else {
            await createInvitationHandler(payload)
            // resetInvitation()
        }
    }

    const deleteHandler = async () => {
        await deleteInvitationHandler(invitation.id)
        dialogCloseHandler()
    }


    useEffect(() => {
        if (openInvitation && selectedInvitation) {
            if (!selectedInvitation?.date) {
                selectedInvitation.date = null
            }
            let payload = { ...selectedInvitation }
            payload.from = payload.from ?? null
            payload.to = payload.to ?? null
            setInvitation({ ...payload })
        }
    }, [openInvitation, selectedInvitation])

    return (
        <Dialog
            dir={theme.direction}
            fullWidth
            fullScreen={fullScreen}
            open={openInvitation}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title" sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box> {isEditing ? t('Invitation Details') : t("Add Invitation")}</Box>
                <IconButton onClick={dialogCloseHandler} color="primary">
                    <ClearIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <form onSubmit={submitHandler}>
                {
                    selectedInvitation &&
                    <Box sx={{ pt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography variant="caption">{t("Invitation Code")}</Typography>
                            <Typography variant='h4'>{get(selectedInvitation, 'uniqueCode')}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "center" }}>
                            <Typography variant='body2'>{t("Status") + ":"}</Typography>
                            <Typography
                                sx={{ marginInlineStart: 4, marginInlineEnd: 2, font: "bold 16px/24px Rabar" }}
                                color={(() => {
                                    const colors = {
                                        [INVITATION_STATUS.created]: "pyriteYellow",
                                        [INVITATION_STATUS.checkedIn]: "straightforwardGreen",
                                        [INVITATION_STATUS.expired]: "vermillionRed",
                                    };
                                    return get(colors, get(selectedInvitation, 'status'))
                                })()}
                            >
                                {t(get(selectedInvitation, 'status'))}
                            </Typography>
                            {
                                get(selectedInvitation, 'status') === INVITATION_STATUS.checkedIn &&
                                <Typography>
                                    {`(${moment(get(selectedInvitation, 'checkInDate')).format(DATE_FORMATS.weekdayDayMonthNameYear)})`}
                                </Typography>
                            }
                        </Box>
                    </Box>
                }
                <DialogContent>
                    <FormControl margin="normal" size='small' fullWidth>
                        <InputLabel id="gender-select-label">{t("Gender")}</InputLabel>
                        <Select
                            MenuProps={{ dir: theme.direction }}
                            name="gender"
                            labelId="gender-select-label"
                            id="gender-select"
                            value={invitation.gender}
                            label={t("Gender")}
                            onChange={invitationGenderHandler}
                        >
                            <MenuItem value={"MALE"}>{t("MALE")}</MenuItem>
                            <MenuItem value={"FEMALE"}>{t("FEMALE")}</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        error={Boolean(errors && errors.label_en)}
                        helperText={errors && errors.label_en}
                        name="label_en"
                        margin="normal"
                        label={t("The English salutation")}
                        variant="outlined"
                        fullWidth
                        size='small'
                        value={invitation.label_en}
                        onChange={invitationChangeHandler}
                    />
                    <TextField
                        error={Boolean(errors && errors.label_ar)}
                        helperText={errors && errors.label_ar}
                        name="label_ar"
                        margin="normal"
                        label={t("The Arabic salutation")}
                        variant="outlined"
                        fullWidth
                        size='small'
                        value={invitation.label_ar}
                        onChange={invitationChangeHandler}
                    />
                    <TextField
                        error={Boolean(errors && errors.description_en)}
                        helperText={errors && errors.description_en}
                        multiline
                        name="description_en"
                        margin="normal"
                        label={t("The English welcome message")}
                        variant="outlined"
                        fullWidth
                        size='small'
                        value={invitation.description_en}
                        onChange={invitationChangeHandler}
                    />
                    <TextField
                        error={Boolean(errors && errors.description_ar)}
                        helperText={errors && errors.description_ar}
                        multiline
                        name="description_ar"
                        margin="normal"
                        label={t("The Arabic welcome message")}
                        variant="outlined"
                        fullWidth
                        size='small'
                        value={invitation.description_ar}
                        onChange={invitationChangeHandler}
                    />
                    <TextField
                        error={Boolean(errors && errors.name)}
                        helperText={errors && errors.name}
                        name="name"
                        margin="normal"
                        label={t("Name")}
                        variant="outlined"
                        fullWidth
                        size='small'
                        value={invitation.name}
                        onChange={invitationChangeHandler}
                    />
                    <MapitPhoneInput
                        margin="normal"
                        label={t("Phone")}
                        name="phone"
                        value={invitation.phone}
                        handleChange={invitationChangeHandler}
                        error={errors && errors.phone}
                    />
                    <MapItLocalizedNumberTextField margin="normal"
                        name="guests"
                        label={t("Guests")}
                        value={invitation.guests}
                        required={true}
                        handleChange={invitationChangeHandler}
                        error={errors && errors.guests}
                        type="number"
                        min={1}
                    />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label={t("Date")}
                            disablePast={true}
                            value={invitation.date || null}
                            onChange={(newValue) => {
                                invitationChangeHandler({ target: { name: "date", value: newValue } });
                            }}
                            renderInput={(params) => <TextField name="date" helperText={'mm/dd/yyyy'} fullWidth size="small" margin="normal" {...params} />}
                        />
                        <Stack direction="row" spacing={8} my={8}>
                            <TimePicker
                                label={t("From")}
                                value={invitation.from || null}
                                onChange={(newValue) => {
                                    invitationChangeHandler({ target: { name: "from", value: newValue } });
                                }}
                                renderInput={(params) => <TextField error={!!errors?.from} helperText={errors?.from} name="from" fullWidth size='small' {...params} />}
                            />
                            <TimePicker
                                label={t("To")}
                                value={invitation.to || null}
                                onChange={(newValue) => {
                                    invitationChangeHandler({ target: { name: "to", value: newValue } });
                                }}
                                renderInput={(params) => <TextField name="to" error={!!errors?.to} helperText={errors?.to} fullWidth size='small' {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                    <FormControl
                        margin="normal" size='small' fullWidth>
                        <InputLabel id="type-select-label">{t("Type")}</InputLabel>
                        <Select
                            MenuProps={{ dir: theme.direction }}
                            name="type"
                            labelId="type-select-label"
                            label={t("Type")}
                            id="type-select"
                            value={invitation.type}
                            onChange={invitationChangeHandler}
                        >
                            <MenuItem value={INVITATION_TYPES.opening}>{t(INVITATION_TYPES.opening)}</MenuItem>
                            {/* <MenuItem value={INVITATION_TYPES.regular}>{t(INVITATION_TYPES.regular)}</MenuItem> */}
                        </Select>
                    </FormControl>
                    {
                        get(invitation, 'gender') === GENDERS.female &&
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBlock: 4 }}>
                            <FormControlLabel
                                sx={{ marginInlineStart: 0 }}
                                control={
                                    <Checkbox
                                        sx={{ color: "#4678A0" }}
                                        name="canSend"
                                        checked={get(invitation, 'canSend') === true}
                                        onChange={invitationChangeHandler}
                                    />
                                }
                                label={t("Can invite friends")}
                            />
                        </Box>
                    }
                    {isEditing && <DownloadInvitationAsImage invitation={invitation} />}
                </DialogContent>
                <Divider />
                <DialogActions>
                    {
                        isEditing &&
                        <ConfirmationDialog
                            sx={{ mx: 20 }}
                            title={t("Warning")}
                            content={t("confirmDeleteMessage", { item: t("invitation") })}
                            confirmAction={deleteHandler}
                            pendingDelete={loading}
                            btnText={t("Delete")}
                        />
                    }
                    <LoadingButton
                        sx={{ textTransform: "none" }}
                        loading={loading}
                        variant='contained'
                        type='submit'
                        disabled={isEditing && includes(
                            [INVITATION_STATUS.checkedIn, INVITATION_STATUS.expired],
                            get(selectedInvitation, 'status')
                        )}
                    >
                        {isEditing ? t('Edit') : t("Add")}
                    </LoadingButton>
                </DialogActions>
            </form>
        </Dialog>
    );
}
