import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import AuthContextProvider from './context';
import StyleWrapper from "Global/StyleWrapper";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <AuthContextProvider>
        <StyleWrapper>
          <App />
        </StyleWrapper>
      </AuthContextProvider>
    </BrowserRouter>
);

