import { Stack, Typography, useTheme } from "@mui/material";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

const GuestName = ({ name, gender, label_en, label_ar, ...rest }) => {
    const { t } = useTranslation();
    const theme = useTheme()
    return (
        <Stack {...rest}>
            <Typography
                sx={{
                    textAlign: "center",
                    font: "normal 18px/28px Rabar",
                    letterSpacing: 0,
                    color: "autumnOrange"
                }}
            >
                {
                    (!!label_ar && !!label_en) ?
                        (theme.direction === "rtl" ? label_ar : label_en)
                        :
                        t(`guestNamePrefix.${gender}`)
                }
            </Typography>
            <Typography
                sx={{
                    textAlign: "center",
                    font: "bold 26px/33px Rabar",
                    letterSpacing: 0,
                    color: "zenithBlue",
                }}
            >
                {name}
            </Typography>
        </Stack>
    )
}

GuestName.propTypes = {
    name: PropTypes.string.isRequired,
    gender: PropTypes.oneOf(['MALE', 'FEMALE']).isRequired,
};

export default GuestName;