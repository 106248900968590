import { Box, CircularProgress } from "@mui/material";
import InvitationDetails from "./InvitationDetails";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import GuestService from "services/GuestService";
import { InvalidInvitation } from "./common";
import { InviteFriends } from "./InviteFriends";
import { INVITATION_DETAILS_TYPES } from "./constants";
import { Banner } from "./InvitationDetails";

const STEPS = {
    invitationDetails: "INVITATION_DETAILS",
    inviteFriends: "INVITE_FRIENDS",
}

const Invitation = ({ type, props}) => {
    let { invitationId } = useParams()
    const [invitation, setInvitation] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    const [currentStep, setCurrentStep] = useState(STEPS.invitationDetails)

    const getInvitation = async (id) => {
        setError(false)
        try {
            setLoading(true);
            let response = await GuestService.getInvitationById(id);
            setInvitation(response.body);
        } catch (error) {
            setError(true)
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getInvitation(invitationId)
    }, [invitationId])

    // Event handlers
    const handleInviteFriendsClick = _ => {
        setCurrentStep(STEPS.inviteFriends)
    }

    if (loading) {
        return (
            <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress color="primary" size={60} />
            </Box>
        )
    }

    if (error) {
        return (
            <InvalidInvitation />
        )
    }

    if (invitation) {
        return (
            <>
                {
                    currentStep === STEPS.invitationDetails && type === INVITATION_DETAILS_TYPES.fullDetails &&
                    <InvitationDetails invitation={invitation} onInviteFriendsClick={handleInviteFriendsClick} />
                }
                {
                    currentStep === STEPS.invitationDetails && type === INVITATION_DETAILS_TYPES.banner &&
                    <Banner invitation={invitation} />
                }
                {
                    currentStep === STEPS.inviteFriends &&
                    <InviteFriends invitation={invitation} onOriginalInviteClicked={_ => setCurrentStep(STEPS.invitationDetails)} />
                }
            </>
        )
    }
}

export default Invitation;