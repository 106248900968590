import { Box, Button } from "@mui/material";
import { get, values } from "lodash";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { STEPS } from "../constants";

const NavigationButtons = ({
    currentStep,
    onNewInviteClicked,
    onAllInvitesClicked,
    onOriginalInviteClicked,
    ...rest
}) => {
    const { t } = useTranslation()

    return (
        <Box
            {...rest}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                ...get(rest, 'sx')
            }}
        >
            {
                currentStep !== STEPS.newInvite &&
                <Button
                    onClick={onNewInviteClicked}
                    variant='contained'
                    fullWidth
                    sx={{
                        height: 44,
                        width: 165,
                        borderRadius: 22,
                        backgroundColor: "autumnOrange",
                        textTransform: "none",
                        font: "bold 16px/23px Rabar"
                    }}
                >
                    {t("inviteFriends.newInvite")}
                </Button>
            }
            {
                currentStep !== STEPS.allInvites &&
                <Button
                    onClick={onAllInvitesClicked}
                    variant='contained'
                    fullWidth
                    sx={{
                        marginTop: 8,
                        height: 44,
                        width: 165,
                        borderRadius: 22,
                        backgroundColor: "autumnOrange",
                        textTransform: "none",
                        font: "bold 16px/23px Rabar"
                    }}
                >
                    {t("inviteFriends.yourInvites")}
                </Button>
            }
            <Button
                onClick={onOriginalInviteClicked}
                variant='contained'
                fullWidth
                sx={{
                    marginTop: 8,
                    height: 44,
                    width: 165,
                    borderRadius: 22,
                    backgroundColor: "zenithBlue",
                    textTransform: "none",
                    font: "bold 16px/23px Rabar"
                }}
            >
                {t("inviteFriends.backToInvitation")}
            </Button>
        </Box>
    )
}

NavigationButtons.propTypes = {
    currentStep: PropTypes.oneOf(values(STEPS)).isRequired,
    onNewInviteClicked: PropTypes.func.isRequired,
    onAllInvitesClicked: PropTypes.func.isRequired,
    onOriginalInviteClicked: PropTypes.func.isRequired
};

export default NavigationButtons;