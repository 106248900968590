import { styled, TextField } from "@mui/material";

export default styled(TextField)(() => ({
    '& fieldset': {
        border: '0px solid #A3BCCF',
        borderRadius: '22px',
    },
    '& .MuiOutlinedInput-root': {
        height: 44,
        '& input': {
            height: 44,
            padding: 0,
            border: '1px solid #A3BCCF',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: 22,
            font: "normal 19px/23px Rabar",
            textAlign: 'center',
            "&:-webkit-autofill": {
                borderRadius: 22,
                WebkitBoxShadow: "inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px rgba(255, 255, 255,1)"
            },
            "&::placeholder": {
                font: 'normal 19px/23px Rabar',
                color: "#D7DCE0",
                opacity: 1
            },
        },
    },
}));