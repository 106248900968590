import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Button } from '@mui/material';
import { get } from "lodash";

const InviteFriends = ({ onClick, ...rest }) => {
    const { t } = useTranslation();
    return (
        <Stack
            {...rest}
            alignItems="center"
            sx={{
                ...get(rest, 'sx')
            }}
        >
            <Typography
                sx={{
                    textAlign: "center",
                    font: "bold 17px/24px Rabar",
                    letterSpacing: 0,
                    color: "zenithBlue"
                }}
            >
                {t(`inviteFriends.message`)}
            </Typography>
            <Button
                onClick={onClick}
                variant='contained'
                sx={{
                    mt: 4,
                    width: 291,
                    height: 44,
                    borderRadius: 22,
                    backgroundColor: "autumnOrange",
                    textTransform: "none",
                    font: "bold 19px/23px Rabar"
                }}
            >
                {t("inviteFriends.buttonTitle")}
            </Button>
        </Stack>
    )
}

export default InviteFriends;