import React from "react";
import { CssBaseline } from "@mui/material";
import { Box } from "@mui/system";
import AppBar from "modules/_shared/UI/AppBar"
import Drawer from "modules/_shared/UI/Drawer"
import { styled } from '@mui/material/styles';
import { Outlet } from "react-router-dom";

const ContentWrapper = styled('div')(({ theme }) => ({
  flexGrow: 1,
  // p: 3,
  marginTop: theme.mixins.toolbar.minHeight,
}
));

export default function MainLayout() {
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar handleDrawerOpen={handleDrawerOpen} />
      <Drawer open={open} handleDrawerOpen={handleDrawerOpen} />
      <ContentWrapper>
        <Outlet />
      </ContentWrapper>
    </Box>
  )
}