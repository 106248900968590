import { Box, CircularProgress } from "@mui/material";
import { get, map } from "lodash";
import { useEffect, useState } from "react";
import GuestService from "services/GuestService";
import Invite from "./Invite";

const AllInvites = ({ originalInvitationId, ...rest }) => {

    const [allInvites, setAllInvites] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(_ => {
        getAllInvites(originalInvitationId);
    }, [originalInvitationId])

    const getAllInvites = async id => {
        const { body: invites } = await GuestService.getAllInvites(id)
        setAllInvites(invites);
        setLoading(false);
    }

    if (loading) {
        return (
            <Box sx={{ height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress color="primary" size={60} />
            </Box>
        )
    } else {
        return (
            <Box
                {...rest}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    ...get(rest, 'sx')
                }}
            >
                {
                    map(
                        allInvites,
                        invite => <Invite invite={invite}/>
                    )
                }
            </Box>
        )
    }
}

export default AllInvites;