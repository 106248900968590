import React, { useLayoutEffect } from "react";
import "../i18n";
import { ThemeProvider, CssBaseline } from "@mui/material";
import GlobalStyles from "Global/GlobalStyles";
import { RTLTheme, LTRTheme } from "Global/theme";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from 'stylis';
import { createTheme } from '@mui/material/styles'
import { AuthContext } from "context";


const StyleWrapper = (props) => {
  const { authState: { language } } = React.useContext(AuthContext);

  const ltrTheme = createTheme(LTRTheme);
  const rtlTheme = createTheme(RTLTheme);

  const cacheLtr = createCache({
    key: "muiltr"
  });

  const cacheRtl = createCache({
    key: "muirtl",
    // prefixer is the only stylis plugin by default, so when
    // overriding the plugins you need to include it explicitly
    // if you want to retain the auto-prefixing behavior.
    stylisPlugins: [prefixer, rtlPlugin]
  });

  useLayoutEffect(() => {
    // document.body.setAttribute("dir", language === "en-US" ? "ltr" : "rtl");
    document.getElementById("root").setAttribute("dir", language === "en-US" ? "ltr" : "rtl");
  }, [language]);

  return (
    <CacheProvider value={language === "en-US" ? cacheLtr : cacheRtl}>
      <ThemeProvider theme={language === "en-US" ? ltrTheme : rtlTheme}>
        <CssBaseline />
        <GlobalStyles />
        {props.children}
      </ThemeProvider>
    </CacheProvider>
  );
};

export default StyleWrapper;
