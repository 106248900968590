import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Divider, IconButton, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ClearIcon from '@mui/icons-material/Clear';
import ConfirmationDialog from 'modules/_shared/ConfirmationDialog';
import { hostValidationSchema } from './validationSchema';
import runJoiValidate from "utils/runJoiValidate";
import { cloneDeep, pick } from 'lodash';
import { LoadingButton } from '@mui/lab';

export default function ResponsiveDialog({
    loading,
    selectedHost,
    openHost,
    closeHostDialog,
    createHostHandler,
    updateHostHandler,
    deleteHostHandler,
}) {
    const { t } = useTranslation()
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isEditing = !!selectedHost;
    const [host, setHost] = useState({
        name: "",
        email: "",
        password: ""
    })
    const [errors, setErrors] = useState(null)

    const hostChangeHandler = ({ target: { name, value } }) => {
        setHost((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const resetHost = () => {
        setErrors(null)
        setHost({
            name: "",
            email: "",
            password: ""
        })
    }
    const dialogCloseHandler = () => {
        closeHostDialog()
        resetHost()
    }

    const submitHandler = async (e) => {
        e.preventDefault()
        setErrors(null)
        let schema = null
        let payload = pick(
            cloneDeep(host),
            ["name", "email", "password"]
        );
        if (isEditing && !!payload.password === false) {
            console.log("here")
            schema = hostValidationSchema({ password: false })
            payload = pick(
                cloneDeep(host),
                ["name", "email"]
            );
        } else {
            schema = hostValidationSchema({ password: true })
        }
        const validationErrors = runJoiValidate(schema, payload);
        console.log(validationErrors);
        if (validationErrors) {
            setErrors(validationErrors)
            return
        }
        if (isEditing) {
            await updateHostHandler(host.id, payload)
            // dialogCloseHandler()
        } else {
            await createHostHandler(payload)
            // dialogCloseHandler()
            // resetHost()
        }
    }

    const deleteHandler = async () => {
        await deleteHostHandler(host.id)
        dialogCloseHandler()
    }


    useEffect(() => {
        if (openHost && selectedHost) {
            setHost({ ...selectedHost, password: "" })
        }
    }, [openHost, selectedHost])

    return (
        <Dialog
            dir={theme.direction}
            fullWidth
            fullScreen={fullScreen}
            open={openHost}
            aria-labelledby="host-dialog-title"
        >
            <DialogTitle id="host-dialog-title" sx={{ display: "flex", justifyContent: "space-between" }}>
                <div> {`${isEditing ? t('Edit') : t("Add")} ${t("host")}`}</div>

                <IconButton onClick={dialogCloseHandler} color="primary">
                    <ClearIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <form onSubmit={submitHandler}>
                <DialogContent>
                    <TextField
                        error={Boolean(errors && errors.name)}
                        helperText={errors && errors.name}
                        name="name"
                        margin="normal"
                        label={t("Name")} variant="outlined" fullWidth size='small' value={host.name} onChange={hostChangeHandler} />

                    <TextField
                        error={Boolean(errors && errors.email)}
                        helperText={errors && errors.email}
                        name="email"
                        margin="normal"
                        label={t("Email")} variant="outlined" fullWidth size='small' value={host.email} onChange={hostChangeHandler} />
                    <TextField
                        error={Boolean(errors && errors.password)}
                        helperText={errors && errors.password}
                        name="password"
                        margin="normal"
                        label={t("Password")} variant="outlined" fullWidth size='small' value={host.password} onChange={hostChangeHandler}
                        type="password" />

                </DialogContent>
                <Divider />
                <DialogActions>
                    {isEditing && <ConfirmationDialog
                        sx={{ mx: 20 }}
                        title={t("Warning")}
                        content={t("confirmDeleteMessage", { item: t("host") })}
                        confirmAction={deleteHandler}
                        pendingDelete={loading}
                        btnText={t("Delete")}
                    />}

                    <LoadingButton sx={{textTransform: "none"}} loading={loading} variant='contained' type='submit' >
                        {isEditing ? t('Edit') : t("Add")}
                    </LoadingButton>
                </DialogActions>
            </form>
        </Dialog>
    );
}
