import { forwardRef } from 'react'
// import TextField from '@mui/material/TextField'
import CustomTextField from '../CustomTextField'
const CustomPhoneInputComponent = (props, ref) => {

  return (
    <CustomTextField
      {...props}
      inputRef={ref}
      fullWidth
      size='small'
      variant='outlined'
      name='phone'
    />
  )
}
export default forwardRef(CustomPhoneInputComponent)