import { Link, Stack, Typography } from "@mui/material";
import { Location, Phone } from 'assets/SmallIcons';
import { Icon } from "modules/_shared";
import PropTypes from 'prop-types';
import { get, keys } from "lodash";

const detailTypes = {
    address: Location,
    phone: Phone,
};

const ContactDetail = ({ type, detail, ...rest }) => {
    if (!type)
        return null

    return (
        <Stack direction="row" {...rest}>
            <Icon svgSource={get(detailTypes, type)} />
            <Typography
                sx={{
                    marginInlineStart: 10,
                    font: "normal 16px/25px Rabar",
                    letterSpacing: 0,
                    color: "zenithBlue",
                }}
            >
                {
                    type === "phone" &&
                    <Link sx={{ color: "#4678A0" }} href={`tel:${detail}`}>{detail}</Link>
                }
                {
                    type === "address" &&
                    <Link sx={{ color: "#4678A0" }} onClick={_ => window.open("https://goo.gl/maps/ycfUMGAYvfYUvgz19", "_blank")}>{detail}</Link>
                }
            </Typography>
        </Stack>
    )
}

ContactDetail.propTypes = {
    type: PropTypes.oneOf(keys(detailTypes)).isRequired,
    detail: PropTypes.string.isRequired
}

export default ContactDetail;