import { Alert, Box, Button, Container, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import AdminService from "services/AdminServices"
import DataTable from "modules/_shared/DataTable";
import { useTranslation } from "react-i18next";
import HostDialog from "../components/hosts/HostDialog"
const $COLUMN_WIDTH = 200;

export default function Hosts() {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [hosts, setHosts] = useState([])
    const [tableOptions, setTableOptions] = useState({
        rowCount: 20,
        page: 1,
        pageSize: 20
    })
    const navigateThroughLoadedData = (page) => {
        setTableOptions((prevState) => ({
            ...prevState,
            page: page,
        }))
    }
    const clearData = () => {
        setHosts([])
        setTableOptions({
            rowCount: 20,
            page: 1,
            pageSize: 20
        })
    }
    const getHosts = async (page = 1) => {
        try {
            setLoading(true)
            let response = await AdminService.getHosts(page)
            let data = response.body
            setHosts(prevState => ([
                ...prevState,
                ...data.docs
            ]))
            setTableOptions((prevState) => ({
                ...prevState,
                rowCount: data.totalDocs,
                page: data.page,
            }))
        } catch (error) {
            console.log("error")
            console.log(error)
        } finally {
            setLoading(false)
        }
    }


    const columns = [
        { field: "name", headerName: t("Name"), width: $COLUMN_WIDTH * 1.5, },
        { field: "email", headerName: t("Email"), width: $COLUMN_WIDTH * 1.5, },
    ];

    useEffect(() => {
        getHosts()
        return () => clearData()
    }, [])

    // ========snackbar=================
    const [snackbarOptions, setSnackbarOptions] = useState({
        open: false,
        message: "",
        status: "success"
    })

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOptions(prevState => ({
            ...prevState,
            open: false,
            message: "",
        }));
    };

    //============Dialog operations============
    const [openHost, setOpenHost] = useState(false)
    const [selectedHost, setSelectedHost] = useState(null)

    const openHostDialog = () => {
        setOpenHost(true)
    }
    const closeHostDialog = () => {
        setOpenHost(false)
        setSelectedHost(null)
    }
    //==============Request Opertion=============
    const [requestLoading, setRequestLoading] = useState(false)
    const deleteHostHandler = async (id) => {
        try {
            setRequestLoading(true)
            await AdminService.deleteHost(id)
            let data = [...hosts]
            let index = data.findIndex(host => host.id === id)
            data.splice(index, 1)
            setHosts([...data])
            setTableOptions((prevState) => ({
                ...prevState,
                rowCount: prevState.rowCount - 1
            }))
            setSnackbarOptions({
                open: true,
                message: t("ActionSuccessMessage", { item: t("host"), action: t("deleted") }),
                status: 'success'
            })
        } catch (error) {
            console.log("error")
            console.log(error)
            setSnackbarOptions({
                open: true,
                message: t(error.response.data.message),
                status: 'error'
            })
        } finally {
            setRequestLoading(false)
        }
    }
    const createHostHandler = async (payload) => {
        try {
            setRequestLoading(true)
            let response = await AdminService.createHost(payload)
            if (tableOptions.rowCount === hosts.length) {
                setHosts((prevState) => [...prevState, { ...response.body }])
            }
            setTableOptions((prevState) => ({
                ...prevState,
                rowCount: prevState.rowCount + 1
            }))
            setSnackbarOptions({
                open: true,
                message: t("ActionSuccessMessage", { item: t("host"), action: t("created") }),
                status: 'success'
            })
            closeHostDialog()
        } catch (error) {
            console.log("error")
            console.log(error)
            setSnackbarOptions({
                open: true,
                message: t(error.response.data.message),
                status: 'error'
            })
        } finally {
            setRequestLoading(false)
        }
    }
    const updateHostHandler = async (id, payload) => {
        try {
            setRequestLoading(true)
            let response = await AdminService.updateHost(id, payload)
            let data = [...hosts]
            let index = data.findIndex(host => host.id === id)
            data[index] = { ...response.body }
            setHosts([...data])
            setSnackbarOptions({
                open: true,
                message: t("ActionSuccessMessage", { item: t("host"), action: t("updated") }),
                status: 'success'
            })
            closeHostDialog()
        } catch (error) {
            console.log("error")
            console.log(error)
            setSnackbarOptions({
                open: true,
                message: t(error.response.data.message),
                status: 'error'
            })
        } finally {
            setRequestLoading(false)
        }
    }


    return (
        <Container sx={{ marginTop: 20}}>
            <HostDialog
                selectedHost={selectedHost}
                loading={requestLoading}
                openHost={openHost}
                closeHostDialog={closeHostDialog}
                deleteHostHandler={deleteHostHandler}
                createHostHandler={createHostHandler}
                updateHostHandler={updateHostHandler}
            />
            <Box display="flex" justifyContent="end">
                <Button sx={{textTransform: "none"}} onClick={openHostDialog} variant="contained">{t("Add")}</Button>
            </Box>
            <Box>
                <DataTable
                    color='secondary'
                    list={hosts}
                    columns={columns}
                    loading={loading}
                    rowCount={tableOptions.rowCount}
                    page={tableOptions.page}
                    pageSize={tableOptions.pageSize}
                    loadMoreData={getHosts}
                    handleRowClick={({ row }) => {
                        setSelectedHost({ ...row })
                        openHostDialog()
                    }}
                    navigateThroughLoadedData={navigateThroughLoadedData}
                    checkbox={false}
                />
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOptions.open}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    // onClose={handleSnackbarClose} 
                    severity={snackbarOptions.status} sx={{ width: '100%' }}>
                    {t(snackbarOptions.message)}
                </Alert>
            </Snackbar>
        </Container>
    )
}