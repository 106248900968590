import { Box, Grid, styled, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from '@mui/lab';
import { AuthContext } from "context";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import runJoiValidate from "utils/runJoiValidate";
import { loginValidationSchema } from "./components/validationSchema"
import { useNavigate } from "react-router-dom";

const CustomTextField = styled(TextField)(() => ({
    '& input': {
        border: "none",
        backgroundColor: "white",
        borderRadius: 27.5,
        height: 27.5,
        "&:-webkit-autofill": {
            borderRadius: 27.5,
            WebkitBoxShadow: "inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px rgba(255, 255, 255,1)"
        },
        font: "normal 16px/23px Rabar",
        paddingInline: 30
    },
    '& fieldset': {
        borderRadius: '27.5px',
    },
}));

export default function Login({ login_role }) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState(null)
    const { authState: { isAuth, loading, error_messages }, login, setLoginMessage } = useContext(AuthContext);

    const loginHandler = async () => {
        setErrors(null)
        const payload = {
            email,
            password
        }
        const validationErrors = runJoiValidate(loginValidationSchema, payload);
        if (validationErrors) {
            setErrors(validationErrors)
            return
        }
        login(login_role, payload)
    }
    const reset = () => {
        setLoginMessage(null)
        setErrors(null)
        setEmail('')
        setPassword('')
    }
    useLayoutEffect(() => {
        reset()
    }, [])
    useEffect(() => {
        if (isAuth) {
            navigate(`/${login_role}`)
        }
    }, [isAuth])
    return (
        <Grid container justifyContent={"center"}>
            <Grid item xl={4} lg={4} md={6} sm={8} xs={11}>
                <Box sx={{ textAlign: "center", px: 15 }} >
                    <CustomTextField
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        error={Boolean(errors && errors.email)}
                        helperText={errors && errors.email}
                        placeholder={t("Email")} fullWidth variant="outlined"
                    />
                    <CustomTextField
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        error={Boolean(errors && errors.password)}
                        helperText={errors && errors.password}
                        type="password" placeholder={t("Password")} sx={{mt: 10}} fullWidth variant="outlined"
                    />
                    <LoadingButton
                        onClick={loginHandler}
                        loading={loading}
                        color="white"
                        variant="outlined"
                        size="large"
                        fullWidth
                        sx={{
                            mt: 10,
                            height: 55,
                            font: "bold 16px/23px Rabar",
                            textTransform: "none",
                            '&:hover': {
                                backgroundColor: 'autumnOrange',
                                color: 'white',
                            },
                            borderRadius: 27.5,
                        }}>{t("Login")}</LoadingButton>
                </Box>
                {!!error_messages &&
                    <Box sx={{ mx: 20, textAlign: "center", mt: 20, p: 7, color: "red", border: "1px solid red", borderRadius: '7px' }}>
                        {t(error_messages)}
                    </Box>
                }
            </Grid>
        </Grid>
    )
}