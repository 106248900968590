import { Box, Typography } from "@mui/material";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomPhoneInput from "./CustomPhoneInput";
import CustomTextField from "./CustomTextField";
import * as Joi from "joi";
import parsePhoneNumber from 'libphonenumber-js';
import runJoiValidate from "utils/runJoiValidate";
import { LoadingButton } from "@mui/lab";

const NewInvitationForm = ({ onSubmit, error, ...rest }) => {
    const { t } = useTranslation()

    const [inviteeDetails, setInviteeDetails] = useState({
        name: "",
    });
    const [errors, setErrors] = useState(null)

    const [loading, setLoading] = useState(false);

    const handleChange = ({ target: { name, value } }) => {
        setInviteeDetails(
            prevState => (
                { ...prevState, [name]: value })
        );
    }

    useEffect(_ => {
        if (error) {
            setLoading(false);
        }
    }, [error]);

    const handleClick = _ => {
        let schema = Joi.object({
            name: Joi.string()
                .required("required")
                .label(t("Name"))
                .messages({
                    'any.required': `{#label} ${t("validationErrors.isRequired")}`,
                    'string.empty': `{#label} ${t("validationErrors.isEmpty")}`,
                }),
            phone: Joi.string()
                .label(t("Phone"))
                .messages({ 'any.required': `{#label} ${t("validationErrors.isRequired")}` })
                .custom((value, helper) => {
                    const parsedPhone = parsePhoneNumber(value, { extract: true });
                    if (!parsedPhone || !parsedPhone.isValid())
                        return helper.message(t("validationErrors.isValidPhone"));
                    return true
                }),
        })
        const validationErrors = runJoiValidate(schema, inviteeDetails);
        setErrors(validationErrors)
        if (validationErrors) {
            return
        }
        setLoading(true)
        onSubmit(inviteeDetails);
    }

    return (
        <Box
            {...rest}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                ...get(rest, 'sx')
            }}
        >
            <CustomTextField
                name="name"
                value={get(inviteeDetails, 'name')}
                onChange={handleChange}
                error={Boolean(errors && get(errors, 'name'))}
                helperText={errors && get(errors, 'name')}
                placeholder={t("Sister Name")}
            />
            <CustomPhoneInput
                name="phone"
                value={get(inviteeDetails, 'phone')}
                onChange={handleChange}
                error={Boolean(errors && get(errors, 'phone'))}
                helperText={errors && get(errors, 'phone')}
                containerProps={{
                    sx: { mt: 8 }
                }}
            />
                        {
                error &&
                <Typography
                    sx={{
                        font: 'bold 12px/13px Rabar',
                        letterSpacing: "-0.05px",
                        mt: 12,
                        mb: 6,
                        textAlign: 'center',
                        color: "#93302a"
                    }}
                >
                    {t(error)}
                </Typography>
            }
            <LoadingButton
                onClick={handleClick}
                variant='contained'
                loading={loading}
                fullWidth
                sx={{
                    mt: 8,
                    height: 44,
                    borderRadius: 22,
                    backgroundColor: "autumnOrange",
                    textTransform: "none",
                    font: "bold 19px/23px Rabar"
                }}
            >
                {t("inviteFriends.invite")}
            </LoadingButton>
        </Box>
    )
}

export default NewInvitationForm;