import http from "services/httpService";
import { $BASE_URL } from "constants";
import { sleep } from "utils";

const apiUrl = $BASE_URL + '/api';

const apiEndpoints = apiUrl + "/v1/user"

async function getInvitationById(id) {
  const response = await http.get(`${apiEndpoints}/invitation/${id}`);
  return response.data;
}

const inviteFriend = async (friendDetails, originalInvitationId) => {
  const response = await http.post(
    `${apiEndpoints}/invitation`,
    {
      ...friendDetails,
      sender: originalInvitationId
    }
  );
  return response.data;
}

const getAllInvites = async id => {
  const response = await http.get(`${apiEndpoints}/my-invitations/${id}`);
  return response.data;
}

const getPostMetadata = async id => {
  console.log(`getting metadata for post with id: ${id}`);
  const details = {
    imageUrl: "https://res.cloudinary.com/dhisioywv/image/upload/w_1200,h_630,c_crop/v1657808520/Screen_Shot_2022-07-14_at_4.20.41_PM_dnqows.png",
    title: "Test Title",
    description: "Test Description"
  }
  sleep(500)
  return details;
}

const GuestService = {
  getInvitationById,
  inviteFriend,
  getAllInvites,
  getPostMetadata
};

export default GuestService;