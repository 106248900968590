import * as React from 'react';
import GlobalStyles from '@mui/material/GlobalStyles';

const inputGlobalStyles = <GlobalStyles styles={{
  "*": {
    boxSizing: "border-box",
    margin: 0,
    padding: 0,
  },
  html: {
    "WebkitFontSmoothing": "antialiased",
    "MozOsxFontSmoothing": "grayscale",
    height: "100%",
    width: "100%",
  },
  body: {
    backgroundColor: "#fffff",
    height: "100%",
    width: "100%",
  },
  a: {
    textDecoration: "none",
  },
  "#root": {
    height: "100%",
    width: "100%",
  },

}} />


const CustomGlobalStyles = () => {
  return <>{inputGlobalStyles}</>
};

export default CustomGlobalStyles;
