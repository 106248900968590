import { Box, Button, Typography } from "@mui/material";
import { get } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import copy from 'copy-to-clipboard';

const InvitationSuccessful = ({ invitationId, onNewInviteClicked, ...rest }) => {
    const { t } = useTranslation()

    const invitationLink = useMemo(_ => {
        if (invitationId) {
            return `${window.location.origin}/guest/${invitationId}`
        }
    }, [invitationId])

    const handleCopyLink = _ => {    
        copy(invitationLink)
    }

    return (
        <Box
            {...rest}
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                ...get(rest, 'sx')
            }} 
        >
            <Box sx={{ textAlign: 'center', marginBottom: 12}}>
                <Typography color="zenithBlue" sx={{ font: 'bold 15px/24px Rabar', letterSpacing: "0px", }}>
                    {t("inviteFriends.copyLinkMessage")}
                </Typography>
                <Typography color="zenithBlue" sx={{ font: 'bold 15px/24px Rabar', letterSpacing: "0px", }}>
                    {invitationLink}
                </Typography>
            </Box>
            <Button
                onClick={handleCopyLink}
                variant='contained'
                fullWidth
                sx={{
                    height: 44,
                    width: 165,
                    borderRadius: 22,
                    backgroundColor: "zenithBlue",
                    textTransform: "none",
                    font: "bold 16px/23px Rabar"
                }}
            >
                {t("inviteFriends.copyLink")}
            </Button>
            {/* <Button
                onClick={onNewInviteClicked}
                variant='contained'
                fullWidth
                sx={{
                    mt: 32,
                    height: 44,
                    width: 165,
                    borderRadius: 22,
                    backgroundColor: "autumnOrange",
                    textTransform: "none",
                    font: "bold 16px/23px Rabar"
                }}
            >
                {t("inviteFriends.newInvite")}
            </Button> */}
        </Box>
    )
}

export default InvitationSuccessful;