import { get, pick } from "lodash";
import { useReducer, createContext } from "react"
import AuthService from "services/AuthService"
import http from "services/httpService";

const initialState = {
    profile: null,
    isAuth: false,
    inital_loading: true,
    loading: false,
    error_messages: null,
    language: localStorage.getItem("i18nextLng"),
    login: (payload) => { },
    register: (payload) => { },
    logout: (payload) => { },
}
export const AuthContext = createContext(initialState)

function AuthReducer(state, { type, payload }) {
    switch (type) {
        case "LOGIN":
            localStorage.setItem("token", payload.token)
            localStorage.setItem("role", payload.role)
            http.setJwt(payload.token)
            return {
                ...state,
                isAuth: true,
                role: payload.role,
                profile: payload.profile
            }
        case "SET_ERROR_MESSAGES":
            return {
                ...state,
                error_messages: payload
            }
        case "SET_PROFILE":
            const role = localStorage.getItem("role")
            return {
                ...state,
                profile: payload,
                isAuth: true,
                role
            }
        case "INITIAL_LOADING":
            return {
                ...state,
                inital_loading: payload
            }
        case "LOADING":
            return {
                ...state,
                loading: payload
            }
        case "LOGOUT":
            localStorage.removeItem("token")
            localStorage.removeItem("role")
            return {
                ...state,
                profile: null,
                isAuth: false
            }
        case "SET_LANGUAGE":
            return {
                ...state,
                language: payload
            }
        default:
            return state

    }
}

function AuthContextProvider({ children }) {
    const [authState, dispatch] = useReducer(AuthReducer, initialState)
    async function login(login_role, payload) {
        try {
            dispatch({ type: "SET_ERROR_MESSAGES", payload: null })
            dispatch({ type: "LOADING", payload: true })
            let response = await AuthService.login(login_role, payload)
            const profile = pick(response, ['email', "id", "name", "role"]);
            if (response.status === 200) {
                dispatch({ type: "LOGIN", payload: { token: response.body.token, role: login_role, profile } })
            }
        } catch (error) {
            const errorMessage = get(error, 'response.data.message')
            errorMessage && dispatch({ type: "SET_ERROR_MESSAGES", payload: errorMessage })
        } finally {
            dispatch({ type: "LOADING", payload: false })
        }
    }
    async function getProfile() {
        try {
            dispatch({ type: "INITIAL_LOADING", payload: true })
            const token = localStorage.getItem("token") || null
            if (token) {
                let response = await AuthService.getProfile()
                dispatch({ type: "SET_PROFILE", payload: response.data.body })
            }
        } catch (error) {
            console.log("error")
            console.log(error)
        } finally {
            dispatch({ type: "INITIAL_LOADING", payload: false })
        }

    }
    function logout(payload) {
        dispatch({ type: "LOGOUT", payload })
    }

    function setLoginMessage(message) {
        dispatch({ type: "SET_ERROR_MESSAGES", payload: message })
    }
    function setCurrentLanguage(language) {
        dispatch({ type: "SET_LANGUAGE", payload: language })
    };
    const value = {
        authState: authState,
        login,
        getProfile,
        logout,
        setLoginMessage,
        setCurrentLanguage
    };
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthContextProvider;