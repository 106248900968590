import { LoadingButton } from '@mui/lab';
import { Box, styled, TextField, Typography } from '@mui/material';
import { get } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import BarcodeScannerComponent from "react-qr-barcode-scanner";
import PropTypes from "prop-types";
import { QrReader } from 'react-qr-reader';

const CustomTextFiled = styled(TextField)(() => ({
    '& input': {
        backgroundColor: "white",
        border: "1px solid #4678A0",
        borderRadius: 27.5,
        "&:-webkit-autofill": {
            borderRadius: 27.5,
            WebkitBoxShadow: "inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px rgba(255, 255, 255,1)",
            height: 27.5
        },
        font: "normal 16px/23px Rabar",
        paddingInline: 30
    },
    '& fieldset': {
        borderRadius: '27.5px',
    },
}));

const CodeScanner = ({ onSubmit }) => {
    const { t } = useTranslation()
    const [scanValue, setScanValue] = useState("");

    const submitClicked = _ => onSubmit(scanValue);

    return (
        <Box sx={{ textAlign: "center" }}>
            <Box>
                <Typography sx={{ font: "bold 25px/32px Rabar", mb: 2 }} color="primary">{t("checkIn.title")}</Typography>
                <Typography sx={{ font: "normal 16px/19px Rabar", whiteSpace: "pre-wrap" }} color="primary">{t("checkIn.message")}</Typography>
            </Box>
            <QrReader
                onResult={result => result && setScanValue(get(result, 'text'))}
                constraints={{facingMode: 'environment'}}
                containerStyle={{
                    marginBlock: 16,
                    borderRadius: '28px',
                    border: '1px solid #4678A0',
                    height: 196,
                    width: 327,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                    // transform: `translate3d(0, 0, 0)`
                }}
                videoContainerStyle={{
                    width: '100%',
                    paddingTop: undefined
                }}
                videoStyle={{
                    width: '100%',
                    height: undefined,
                    position: undefined,
                    top: undefined,
                    left: undefined,
                }}
            />
            <Box>
                <CustomTextFiled
                    value={scanValue}
                    onChange={event => setScanValue(get(event, 'target.value'))}
                    placeholder={t("Enter Invitation Code here")}
                    fullWidth
                    variant="outlined"
                    sx={{
                        height: "55px",
                    }}
                />
            </Box>
            <Box>
                <LoadingButton
                    onClick={submitClicked}
                    variant='contained'
                    fullWidth
                    disabled={!scanValue}
                    sx={{
                        font: "bold 19px/23px Rabar",
                        padding: 10,
                        mt: 15,
                        backgroundColor: "#ED8B35",
                        height: 55,
                        borderRadius: 20,
                        textTransform: "none"
                    }}
                >{t("Submit")}
                </LoadingButton>
            </Box>
        </Box>
    )
}

CodeScanner.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default CodeScanner;