import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Typography, Button, useTheme, useMediaQuery, styled } from "@mui/material";
import { useTranslation } from 'react-i18next';

import Page from "modules/_shared/Page";

const ImageContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(6),
  display: "flex",
  justifyContent: "center",
}));
const ButtonContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(6),
  display: "flex",
  justifyContent: "center",
}));

const ErrorServerDown = () => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();

  return (
    <Page sx={(theme) => ({
      padding: theme.spacing(3),
      display: "flex",
      flexDirection: "column",
      alignContent: "center",
    })} title="Server Down">
      <Typography align="center" variant={mobileDevice ? "h4" : "h1"}>
        {t("Error:500 - Server Down")}
      </Typography>
      <Typography align="center" variant="body2">
        {t("Error:Something went wrong!")}
      </Typography>
      <ImageContainer>
        <img
          alt="Under development"
          style={{
            maxWidth: "100%",
            width: 560,
            maxHeight: 300,
            height: "auto",
          }}
          src="/static/images/undraw_server_down_s4lk.svg"
        />
      </ImageContainer>
      <ButtonContainer>
        <Button
          component={RouterLink}
          to="/"
          sx={{ textTransform: "none" }}
          variant="outlined"
        >
          {t("Back to home")}
        </Button>
      </ButtonContainer>
    </Page>
  );
};

export default ErrorServerDown;
