import { Outlet } from "react-router-dom";
import { experimentalStyled } from "@mui/material";
//TODO: make header common component
// import Header from 'src/views/LandingViews/components/Header';

const MainLayoutRoot = experimentalStyled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const MainLayoutWrapper = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const MainLayoutContainer = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const MainLayoutContent = experimentalStyled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
});

const MainLayout = (props) => {
  return (
    <MainLayoutRoot>
      {/* {props.navbarVisible && <Header />} */}
      <MainLayoutWrapper sx={{ pt: props.navbarVisible ? "64px" : 0 }}>
        <MainLayoutContainer >
          <MainLayoutContent sx={{ pt: '48px' }}>
            <Outlet />
          </MainLayoutContent>
        </MainLayoutContainer>
      </MainLayoutWrapper>
    </MainLayoutRoot>
  );
};

export default MainLayout;
