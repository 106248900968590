import { Container, Box } from "@mui/material";
import { GuestName, WelcomeMessage } from "./components";
import { SicilyLogo } from "modules/_shared"
import { get } from "lodash";

const Banner = ({ invitation }) => {
    return (
        <Box sx={{ minHeight: '100%', display: "flex", flexDirection: "row", alignItems: 'center', }}>
            <Container fixed maxWidth="xs" sx={{ flexGrow: 1 }} >
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <SicilyLogo style={{ height: 69, cursor: "pointer"}} />
                    <GuestName
                        name={get(invitation, 'name')}
                        gender={get(invitation, 'gender')}
                        label_en={get(invitation, 'label_en')}
                        label_ar={get(invitation, 'label_ar')}
                        sx={{ marginTop: 20 }} />
                    <WelcomeMessage
                        description_en={get(invitation, 'description_en')}
                        description_ar={get(invitation, 'description_ar')}
                        sx={{ marginTop: 20 }} />
                </Box>
            </Container>
        </Box>
    )
}

export default Banner;