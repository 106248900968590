import { Box, Button, Typography } from '@mui/material';
import { AuthContext } from 'context';
import { get } from 'lodash';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function HostLandingPage() {
    const { authState: { profile } } = useContext(AuthContext);
    const { t } = useTranslation()
    const navigate = useNavigate()
    return (
        <Box sx={{ textAlign: "center" }}>
            <Typography sx={{ font: "normal 19px/23px Rabar" }} color="primary" >
                {t("Welcome,")}
            </Typography>
            <Typography sx={{ font: "bold 25px/32px Rabar" }} color="primary">
                {get(profile, "name")}
            </Typography>
            <Button
                onClick={() => navigate("/host/checkIn")}
                variant='contained'
                sx={{
                    mt: 20,
                    width: 327,
                    height: 55,
                    borderRadius: 27.5,
                    backgroundColor: "#ED8B35",
                    textTransform: "none",
                    font: "bold 16px/19px Rabar"
                }}
            >
                {t("Check In new guests")}
            </Button>
        </Box>
    )
}