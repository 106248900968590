import { Container, Box, Stack } from "@mui/material";
import { GuestName, WelcomeMessage, ContactDetails, QRCode, InviteFriends } from "./components";
import { Footer, SicilyLogo } from "modules/_shared"
import { get } from "lodash";
import { AttendanceDetails, SocialDetails } from '../common'

const InvitationDetails = ({ invitation, onInviteFriendsClick, display, ...props }) => {
    return (
        <Box display={display ?? "block"} {...props}>
            <Box minHeight="100%" display="flex" flexDirection="column">
                <Container fixed maxWidth="xs" sx={{ flexGrow: 1, paddingInline: 48 }} >
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <SicilyLogo style={{ marginTop: 30, height: 69, cursor: "pointer" }} />
                        <GuestName
                            name={get(invitation, 'name')}
                            gender={get(invitation, 'gender')}
                            label_en={get(invitation, 'label_en')}
                            label_ar={get(invitation, 'label_ar')}
                            sx={{ marginTop: 20 }} />
                        <WelcomeMessage
                            description_en={get(invitation, 'description_en')}
                            description_ar={get(invitation, 'description_ar')}
                            sx={{ marginTop: 20 }} />
                        <Stack>
                            <AttendanceDetails invitation={invitation} sx={{ marginTop: 20 }} />
                            <QRCode uniqueCode={get(invitation, 'uniqueCode')} qrCode={get(invitation, 'qrCode')} sx={{ marginTop: 25 }} />
                            <ContactDetails sx={{ marginTop: 20 }} />
                            {get(invitation, 'canSend') === true && <InviteFriends sx={{ mt: 30 }} onClick={onInviteFriendsClick} />}
                            <SocialDetails sx={{ marginTop: 27 }} />
                        </Stack>
                    </Box>
                </Container>
                <Footer color="blue" />
            </Box>
        </Box>
    )
}

export default InvitationDetails;