import { Container, Box, Stack, Typography } from "@mui/material";
import { AttendanceDetails, SocialDetails } from '../common'
import { Footer, SicilyLogo } from "modules/_shared"
import { useTranslation } from "react-i18next";
import { NewInvitationForm, InvitationSuccessful, NavigationButtons, AllInvites } from "./components";
import { useState } from "react";
import { get, includes } from "lodash";
import { STEPS } from "./constants";
import PropTypes from "prop-types";
import GuestService from "services/GuestService";

const InviteFriends = ({ invitation, onOriginalInviteClicked }) => {

    const { t } = useTranslation();
    const [currentStep, setCurrentStep] = useState(STEPS.newInvite);
    const [successfulInvitationId, setSuccessfulInvitationId] = useState();
    const [newInvitationError, setNewInvitationError] = useState();

    // clicked from new invitation form component
    const handleSubmitNewInvitation = async inviteeDetails => {
        setNewInvitationError();
        try {
            let response = await GuestService.inviteFriend(inviteeDetails, get(invitation, 'id'));
            setSuccessfulInvitationId(get(response, 'body.id'))
            setTimeout(_ => setCurrentStep(STEPS.sharingNewInvite), 200);
        } catch (error) {
            setNewInvitationError(get(error, 'response.data.message'))
        }
    }

    // clicked from invitation successful component
    const handleNewInviteClicked = _ => {
        setSuccessfulInvitationId();
        setCurrentStep(STEPS.newInvite)
    }

    return (
        <Box sx={{ minHeight: "100%", display: "flex", flexDirection: "column" }}>
            <Container fixed maxWidth="xs" sx={{ flexGrow: 1, paddingInline: 48 }} >
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <SicilyLogo style={{ marginTop: 30, height: 69, cursor: "pointer" }} />
                    <Typography color="zenithBlue" style={{ marginTop: 24, font: 'bold 24px/24px Rabar' }}>
                        {t(currentStep === STEPS.allInvites ? "Your Invitations" : "Invite a friend")}
                    </Typography>
                    <Stack sx={{width: '100%'}}>
                        {
                            includes([STEPS.newInvite, STEPS.sharingNewInvite], currentStep) &&
                            <AttendanceDetails invitation={invitation} sx={{ marginTop: 20 }} />
                        }
                        {
                            currentStep === STEPS.newInvite &&
                            <NewInvitationForm
                                sx={{ marginTop: 16 }}
                                onSubmit={handleSubmitNewInvitation}
                                error={newInvitationError}
                            />
                        }
                        {
                            currentStep === STEPS.sharingNewInvite &&
                            <InvitationSuccessful
                                sx={{ marginTop: 16 }}
                                invitationId={successfulInvitationId}
                                onNewInviteClicked={handleNewInviteClicked}
                            />
                        }
                        {
                            currentStep === STEPS.allInvites &&
                            <AllInvites
                                sx={{ marginTop: 16 }}
                                originalInvitationId={get(invitation, 'id')}
                            />
                        }
                        <NavigationButtons
                            currentStep={currentStep}
                            sx={{ marginTop: 32 }}
                            onNewInviteClicked={_ => setCurrentStep(STEPS.newInvite)}
                            onAllInvitesClicked={_ => setCurrentStep(STEPS.allInvites)}
                            onOriginalInviteClicked={onOriginalInviteClicked}
                        />
                        <SocialDetails sx={{ marginTop: 27 }} />
                    </Stack>
                </Box>
            </Container>
            <Footer color="blue" />
        </Box>
    )
}

InviteFriends.propTypes = {
    invitation: PropTypes.object.isRequired,
    onOriginalInviteClicked: PropTypes.func.isRequired
};

export default InviteFriends;