import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

const WelcomeMessage = ({ description_en, description_ar, ...rest }) => {
    const { t } = useTranslation();
    const theme = useTheme()
    return (
        <Box {...rest}>
            <Typography
                sx={{
                    textAlign: "center",
                    font: "bold 17px/24px Rabar",
                    letterSpacing: 0,
                    color: "zenithBlue",
                    whiteSpace: "pre-wrap" // for line break support
                }}
            >
                {
                    (!!description_ar && !!description_en) ?
                        (theme.direction === "rtl" ? description_ar : description_en)
                        :
                        t(`welcomeMessage`)
                }
            </Typography>
        </Box>
    )
}

export default WelcomeMessage;