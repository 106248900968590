import { Box, Button, Typography } from '@mui/material';
import { Right } from 'assets/icons';
import { Icon } from 'modules/_shared';
import { DATE_FORMATS } from "constants";
import FormatDate from 'utils/FormatDate';
import { get } from 'lodash';
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';

const InvitationDetails = ({ invitation, onCheckIn }) => {

    const { t } = useTranslation()
    const checkInClicked = _ => onCheckIn(get(invitation, 'id'));

    return (
        invitation ?
            <Box sx={{ textAlign: "center" }}>
                <Box>
                    <Icon svgSource={Right} height={100} width={100} />
                </Box>
                <Box my={24}>
                    <Typography color="primary" sx={{ font: 'normal 20px/25px Rabar' }}>{t("Guest name")}</Typography>
                    <Typography color="primary" sx={{ font: 'bold 20px/25px Rabar' }}>
                        {get(invitation, 'name')}
                    </Typography>
                    <Typography color="primary" sx={{ font: 'normal 20px/25px Rabar', mt: 16 }}>{t("Guest numbers")}</Typography>
                    <Typography color="primary" sx={{ font: 'bold 20px/25px Rabar' }}>
                        {get(invitation, 'guests')}
                    </Typography>
                    <Typography color="primary" sx={{ font: 'normal 20px/25px Rabar', mt: 16 }}>{t("Invitation Date")}</Typography>
                    <Typography color="primary" sx={{ font: 'bold 20px/25px Rabar' }}>
                        <FormatDate date={get(invitation, 'date')} format={DATE_FORMATS.dayMonthYear} />
                    </Typography>
                </Box>
                <Button
                    onClick={checkInClicked}
                    variant='contained'
                    sx={{
                        mt: 20,
                        backgroundColor: "#ED8B35",
                        width: 327,
                        height: 55,
                        borderRadius: 27.5,
                        textTransform: "none",
                        font: 'bold 19px/23px Rabar'
                    }}
                >{t("Check In")}
                </Button>
            </Box>
            :
            null
    )
}


InvitationDetails.propTypes = {
    invitation: PropTypes.object.isRequired,
    onCheckIn: PropTypes.func.isRequired,
};

export default InvitationDetails;