import { useContext, useEffect } from "react"
import 'i18n';
import generateRoutes from 'router';
import { useRoutes } from 'react-router-dom';
import { AuthContext } from "context";
import { Box, CircularProgress } from "@mui/material";

function App() {
  const { authState: { inital_loading, role, isAuth }, getProfile } = useContext(AuthContext);
  const routes = useRoutes(generateRoutes({ role, isAuth }));

  useEffect(() => {
    getProfile()
  }, [])

  return (
    <div className="App" style={{ height: "100%" }}>
      {
        inital_loading
          ?
          <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <CircularProgress color="primary" size={80} />
          </Box>
          :
          routes
      }
    </div>
  );
}

export default App;
