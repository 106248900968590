import { Stack, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import {$cloudinaryURL} from "constants"

const QRCode = ({ qrCode, uniqueCode, ...rest }) => {
    const { t, i18n } = useTranslation();
    return (
        <Stack direction="row" {...rest}>
            <Stack>
                <Box sx={{width: 108, height: 108, overflow: "hidden", border: "none"}}>
                    <img  alt="img" style={{objectFit: "cover", width: 108+32, height: 108+32, margin: -16}} src={`${$cloudinaryURL}/${qrCode}`} />
                </Box>
            </Stack>
            <Stack sx={{ marginInlineStart: 12, alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                <Typography
                    sx={{
                        font: "normal 14px/17px Rabar",
                        letterSpacing: 0,
                        color: "autumnOrange",
                        whiteSpace: "pre-wrap" // for line break support
                    }}
                >
                    {t(`qrCodeDisclaimer`)}
                </Typography>
                <Typography
                    sx={{
                        font: "bold 18px/30px Rabar",
                        letterSpacing: 9,
                        color: "zenithBlue",
                        marginInlineStart: i18n.dir() === "rtl" ? -9 : 0,
                        marginBlockEnd: -5
                    }}
                >
                    {uniqueCode}
                </Typography>
            </Stack>
        </Stack>
    )
}

export default QRCode;