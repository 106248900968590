// import { colors } from "@mui/material";
// color names supported by https://colornamer.robertcooper.me/

const baseTheme = {
  palette: {
    primary: {
      main: '#4678A0',
    },
    secondary: {
      main: "#ED8B35",
    },
    LightBlue: {
      main: "#618AB2"
    },
    autumnOrange: "#EF994D",
    zenithBlue: "#4678A0",
    white: {
      main: "#FFF"
    },
    straightforwardGreen: "#53A551",
    vermillionRed: "#CB444A",
    pyriteYellow: "#F7C444",
  },
  typography: {
    fontFamily: "Rabar",
  },
  spacing: 1
}

const LTRTheme = {
  ...baseTheme,
  direction: 'ltr',
};

const RTLTheme = {
  ...baseTheme,
  direction: 'rtl',
};

export {
  LTRTheme,
  RTLTheme
};
