import { Stack, IconButton, Link, Typography } from "@mui/material";
import { Tiktok, Twitter, Instagram } from 'assets/SmallIcons';
import { Icon } from "modules/_shared";
import { useTranslation } from "react-i18next";

const SocialDetails = (props) => {
    const { t } = useTranslation()
    return (
        <Stack {...props}>
            <Stack direction={"row"} sx={{ justifyContent: 'center' }}>
                <IconButton onClick={_ => window.open("https://www.tiktok.com/@Sicilee_sa", "_blank")} >
                    <Icon svgSource={Tiktok} />
                </IconButton>
                <IconButton onClick={_ => window.open("https://twitter.com/Sicilee_sa", '_blank')}>
                    <Icon svgSource={Twitter} />
                </IconButton>
                <IconButton onClick={_ => window.open("https://www.instagram.com/sicilee_sa", '_blank')}>
                    <Icon svgSource={Instagram} />
                </IconButton>
            </Stack>
            {/* <Button component={Link} onClick={() => navigate("/")}></Button> */}
            <Link href="/" sx={{mt: 2, textAlign: "center"}}>
                <Typography variant="button" sx={{fontWeight: "bold"}}>{t("Show menu")}</Typography>
            </Link>
        </Stack>
    )
}

export default SocialDetails;