import { Box } from '@mui/material';
import { GraphicsBlue, GraphicsWhite } from 'assets/images';
// import PropTypes from 'prop-types';
import { useMemo } from 'react';

const Footer = ({ color, ...props }) => {

  const source = useMemo(_ => {
    switch (color) {
      case "blue":
        return GraphicsBlue;
      case "white":
        return GraphicsWhite;
    }
  }, [color])

  return (
    <Box
      display="flex"
      flexDirection="row"
      width="100%"
      mt={3}
      sx={{ overflow: 'hidden', display: 'flex', justifyContent: 'center' }}
      {...props}
    >
      <img
        alt="logo"
        src={source}
        style={{ height: 170, objectFit: 'cover' }}
      />
    </Box>
  )
}

// Footer.propTypes = {
//   type: PropTypes.oneOf(["blue", "white"]).isRequired,
//   detail: PropTypes.string.isRequired
// }

export default Footer;