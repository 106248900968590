import http from "services/httpService";
import { $BASE_URL } from "constants";

const apiUrl = $BASE_URL + '/api';

const apiEndpoints = {
  admin: apiUrl + "/v1/admin-panel",
  host: apiUrl + "/v1/host",
}

export async function login(login_role, payload) {
  const response = await http.post(`${apiEndpoints[login_role]}/login`, { ...payload });
  return response.data;
}

export function getJwt() {
 return localStorage.getItem("token");
}

export async function getProfile() {
  const response = await http.get(apiUrl + "/v1/public/me");
  return response;
}

http.setJwt(getJwt());

const AuthService = {
  login,
  getJwt,
  getProfile
};

export default AuthService;
