import React, { useEffect, useRef, useState } from "react";
import generateDataGridLocaleText from "utils/generateDataGridLocaleText";
import { useTranslation } from "react-i18next";
import { slice } from 'lodash';
import { Box, Card } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material";

const CustomStyleDataGrid = styled(DataGrid)(({ theme }) => ({
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
        outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-row': {
        cursor: "pointer"
    },
    // '&.MuiDataGrid-root .MuiTablePagination-toolbar': {
    //     direction: theme.direction === 'rtl' ? 'ltr' : ''
    // },
    // '&.MuiDataGrid-root  .MuiTablePagination-actions': {
    //     direction: theme.direction === 'rtl' ? 'rtl' : ''
    // }
}));

const DataTable = ({
    list,
    page,
    pageSize,
    rowCount,
    columns,
    loading,
    handleRowClick,
    getSelectedRows,
    loadMoreData,
    navigateThroughLoadedData,
    checkbox = false,
    rowId = null,
    height = "90%",
    getRowClassName,
    ...props
}) => {

    const theme = useTheme()
    const { t } = useTranslation();
    const [selectionModel, setSelectionModel] = useState([]);
    const [paginatedList, setPaginatedList] = useState([]);
    const virtualScrollerElement = useRef(null);
    const headerElement = useRef(null);

    // Hande datagrid header scroll when it's RTL
    useEffect(() => {
        if (theme.direction === "rtl") {
            let doc = document.getElementsByClassName("MuiDataGrid-virtualScroller")
            if (!virtualScrollerElement.current && doc && doc.length) {
                virtualScrollerElement.current = doc[0]
                virtualScrollerElement.current.addEventListener("scroll", function (event) {
                    if (!headerElement.current) {
                        let header = document.getElementsByClassName("MuiDataGrid-columnHeadersInner")[0]
                        headerElement.current = header
                    }
                    headerElement.current.style.transform = `translate3d(${Math.ceil(doc[0].scrollLeft * -1)}px, 0px, 0px)`
                })
            }
        }
    })

    useEffect(() => {
        const sliceStart = pageSize * (page - 1);
        const sliceEnd = pageSize * page;
        const rowsToDisplay = slice(list, sliceStart, sliceEnd);
        setPaginatedList(rowsToDisplay);
    }, [list, rowCount, pageSize, page]);

    const onSelectionModelChange = (newSelectionModel) => {
        setSelectionModel(newSelectionModel);
        getSelectedRows(newSelectionModel)
    }

    const onPageChange = targetPage => {
        const actualTargetPage = targetPage + 1;
        if (actualTargetPage < page) {
            // navigating back
            navigateThroughLoadedData(actualTargetPage);
            return;
        } else {
            // navigate forward
            const numberOfPagesLoaded = Math.ceil(list.length / pageSize);
            if (actualTargetPage > numberOfPagesLoaded) {
                loadMoreData(actualTargetPage)
            } else {
                navigateThroughLoadedData(actualTargetPage);
            }

        }
    }

    return (
        <Box sx={{ display: "flex",flexDirection:"column", mt: 20 }}>
            <Card sx={{ height: '80vh', overflowX: 'auto' }} onScroll={(event) => console.log(event)}
            >
                {/* <PerfectScrollbar> */}
                <CustomStyleDataGrid
                    rows={paginatedList}
                    columns={columns}
                    //   className={classes.root}
                    disableSelectionOnClick
                    checkboxSelection={checkbox}
                    loading={loading}
                    selectionModel={selectionModel}
                    onSelectionModelChange={onSelectionModelChange}
                    localeText={generateDataGridLocaleText(t)}
                    onRowClick={row => handleRowClick(row)}
                    // autoPageSize
                    getRowClassName={getRowClassName}
                    pagination
                    paginationMode="server"
                    rowCount={rowCount}
                    pageSize={pageSize}
                    onPageChange={onPageChange}
                    getRowId={(row) => rowId ? row[rowId] : row.id}
                    rowsPerPageOptions={[20]}
                    {...props}
                />
                {/* </PerfectScrollbar> */}
            </Card>
        </Box>
    );
}

DataTable.propTypes = {
    list: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default DataTable