import { Alert, Box, CircularProgress, Snackbar } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HostService from 'services/HostService';
import { INVITATION_STATUS } from "constants"
import { CodeScanner, InvitationCheckInResult, InvitationDetails } from './components';
import { get } from 'lodash';

export default function HostLandingPage() {
    const { t } = useTranslation();
    const [currentStep, setCurrentStep] = useState("SCAN");
    const [invitation, setInvitation] = useState(null);
    const [snackbarSettings, setSnackbarSettings] = useState({ open: false, message: "" });

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarSettings({open: false});
    };

    const submitScanValueHandler = async code => {
        if (!code) return;
        try {
            setCurrentStep("LOADING");
            let response = await HostService.getInvitationByCode(code);
            const { body: invitation } = response;
            setInvitation(invitation);
            switch (get(invitation, 'status')) {
                case INVITATION_STATUS.created:
                    setCurrentStep("VALID");
                    break;
                case INVITATION_STATUS.expired:
                    setCurrentStep("EXPIRED");
                    break;
                case INVITATION_STATUS.checkedIn:
                    setCurrentStep("ALREADY_CHECKED_IN");
                    break;
                default: break;
            }
        } catch (error) {
            const { status, message } = get(error, 'response.data');
            if (status === 400) {
                setCurrentStep("NOT_FOUND");
            } else {
                setSnackbarSettings({ open: true, message })
                setCurrentStep("SCAN")
            }
        }
    }

    const checkInInvitationHandler = async (id) => {
        if (!id) return;
        try {
            setCurrentStep("LOADING");
            await HostService.updateInvitationStatus(id);
            setCurrentStep("SUCCESS");
        } catch (error) {
            const message = get(error, 'response.data.message');
            setSnackbarSettings({open: true, message});
            setCurrentStep("SCAN");
        }
    }

    return (
        <>
            {
                currentStep === "SCAN" &&
                <CodeScanner onSubmit={submitScanValueHandler} />
            }
            {
                currentStep === "VALID" &&
                <InvitationDetails invitation={invitation} onCheckIn={checkInInvitationHandler} />
            }
            {
                currentStep === "SUCCESS" &&
                <InvitationCheckInResult
                    type="success"
                    invitation={invitation}
                    onCheckInOthers={_ => setCurrentStep("SCAN")}
                />
            }
            {
                currentStep === "ALREADY_CHECKED_IN" &&
                <InvitationCheckInResult
                    type="error"
                    invitation={invitation}
                    onCheckInOthers={_ => setCurrentStep("SCAN")}
                    failReason={INVITATION_STATUS.checkedIn}
                />
            }
            {
                currentStep === "EXPIRED" &&
                <InvitationCheckInResult
                    type="error"
                    invitation={invitation}
                    onCheckInOthers={_ => setCurrentStep("SCAN")}
                    failReason={INVITATION_STATUS.expired}
                />
            }
            {
                currentStep === "NOT_FOUND" &&
                <InvitationCheckInResult
                    type="error"
                    invitation={invitation}
                    onCheckInOthers={_ => setCurrentStep("SCAN")}
                    failReason={"NOT_FOUND"}
                />
            } 
            {
                currentStep === "LOADING" &&
                <Box sx={{ textAlign: "center" }}>
                    <CircularProgress color="primary" size={60} />
                </Box>
            }
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={get(snackbarSettings, 'open')}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    severity={"error"}
                    sx={{ width: '100%' }}>
                    {t(get(snackbarSettings, 'message'))}
                </Alert>
            </Snackbar>
        </>
    )
}