import { Stack } from "@mui/material";
import moment from "moment"
import 'moment/locale/ar';
import { t } from "i18next";
import { DATE_FORMATS } from "constants";
import AttendanceDetail from "./AttendanceDetail";
import { useMemo } from "react";
//moment.locale(localStorage.getItem("i18nextLng"));

const AttendanceDetails = ({ invitation, ...rest }) => {
    const getNumber = (data) => {
        let result = data.split(':')[0]
        let firstChar = result.split("")[0]
        if (firstChar === '0' || firstChar === '٠') {
            result = result.split("")[1]
        }else if(Number(result) > 12){
            return Number(result) - 12
        }
        return result
    }
    const timeDetailes = useMemo(() => {
        // let from = invitation?.from ?? "2022-10-13T11:00:00.000Z"
        // let to = invitation?.to ?? "2022-10-13T23:00:00.000Z"
        let from = null
        if (invitation?.from?.length === 5) {
            from = getNumber(invitation?.from)
        } else if (!!invitation?.from) {
            from = getNumber(moment(invitation?.from).format("hh:mm"))
        } else {
            from = getNumber("11:00")
        }
        let to = null
        if (invitation?.to?.length === 5) {
            to = getNumber(invitation?.to)
        } else if (!!invitation?.to) {
            to = getNumber(moment(invitation?.to).format("hh:mm"))
        } else {
            to = getNumber("23:00")
        }

        return t("OpeningTime", {
            from: from,
            to: to
        })
    }, [invitation])
    return (
        <Stack spacing={7} {...rest}>
            {!!invitation.date && <AttendanceDetail type="date" detail={moment(invitation.date).format(DATE_FORMATS.weekdayDayMonthNameYear)} />}
            {(!!invitation.from || !!invitation.to) && <AttendanceDetail type="time" detail={timeDetailes} />}
            <AttendanceDetail type="guestsNumber" detail={t("guestsInvitation", { guests: invitation.guests })} />
        </Stack>
    )
}

export default AttendanceDetails;