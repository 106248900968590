import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { I18N_LANGUAGES } from "constants";
import ar from "./ar.json";
import en from "./en.json";

const resources = {
  [I18N_LANGUAGES.en]: {
    main: en,
  },
  [I18N_LANGUAGES.ar]: {
    main: ar,
  },
};

const currentLang = localStorage.getItem("i18nextLng")
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    lng: currentLang ? currentLang : I18N_LANGUAGES.ar, // default language
    defaultNS: 'main',
    fallbackLng: I18N_LANGUAGES.en,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
