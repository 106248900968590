import http from "services/httpService";
import { $BASE_URL } from "constants";

const apiUrl = $BASE_URL + '/api';

const apiEndpoints = apiUrl + "/v1/host/invitation"

async function getInvitationByCode(code) {
  const response = await http.get(`${apiEndpoints}/${code}`);
  return response.data;
}

async function updateInvitationStatus(id) {
  const response = await http.put(`${apiEndpoints}/check-in/${id}`);
  return response.data;
}

const HostService = {
  getInvitationByCode,
  updateInvitationStatus
};


export default HostService;