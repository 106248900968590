import React from "react";
import { CssBaseline, Container } from "@mui/material";
import { Box } from "@mui/system";
import AppBar from "modules/_shared/UI/AppBar"
import { styled } from '@mui/material/styles';
import { Outlet } from "react-router-dom";
import { Footer, SicilyLogo } from "modules/_shared";

const ContentWrapper = styled('div')(({ theme }) => ({
  paddingTop: theme.mixins.toolbar.minHeight,
  minHeight: "100%", 
  display: "flex", 
  flexDirection: "column"
})
);

export default function MainLayout() {
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <ContentWrapper sx={{ display: "flex", flexDirection: "column", }}>
    <CssBaseline />
      <AppBar handleDrawerOpen={handleDrawerOpen} />
        <Container fixed maxWidth="xs" sx={{flexGrow: 1, paddingInline: 48, marginTop: 40 }} >
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <SicilyLogo style={{ marginBottom: 30, height: 69, cursor: "pointer"  }} />
            <Outlet />
          </Box>
        </Container>
        <Footer color="white" mt={40} />
    </ContentWrapper>
  )
}