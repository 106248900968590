import * as Joi from "joi";
import i18n from "i18n";
import parsePhoneNumber from 'libphonenumber-js';

export const invitationValidationSchema = Joi.object({
    name: Joi.string()
        .required("required")
        .label(i18n.t("Name"))
        .messages({
            'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
            'string.empty': `{#label} ${i18n.t("validationErrors.isEmpty")}`,
        }),
    label_ar: Joi.string()
        .required("required")
        .label(i18n.t("The Arabic salutation"))
        .messages({
            'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
            'string.empty': `{#label} ${i18n.t("validationErrors.isEmpty")}`,
        }),
    label_en: Joi.string()
        .required("required")
        .label(i18n.t("The English salutation"))
        .messages({
            'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
            'string.empty': `{#label} ${i18n.t("validationErrors.isEmpty")}`,
        }),
    description_ar: Joi.string()
        .required("required")
        .label(i18n.t("The Arabic welcome message"))
        .messages({
            'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
            'string.empty': `{#label} ${i18n.t("validationErrors.isEmpty")}`,
        }),
    description_en: Joi.string()
        .required("required")
        .label(i18n.t("The English welcome message"))
        .messages({
            'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
            'string.empty': `{#label} ${i18n.t("validationErrors.isEmpty")}`,
        }),
    date: Joi.string()
        .allow(null)
        .label(i18n.t("Date"))
        .messages({
            'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
            'string.empty': `{#label} ${i18n.t("validationErrors.isEmpty")}`,
        }),
    from: Joi.string()
        .allow(null)
        .label(i18n.t("From"))
        .messages({
            'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
            'string.empty': `{#label} ${i18n.t("validationErrors.isEmpty")}`,
        }),
    to: Joi.string()
        .allow(null)
        .label(i18n.t("To"))
        .messages({
            'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
            'string.empty': `{#label} ${i18n.t("validationErrors.isEmpty")}`,
        }),
    gender: Joi.string()
        .required("required")
        .label(i18n.t("Gender"))
        .messages({
            'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
            'string.empty': `{#label} ${i18n.t("validationErrors.isEmpty")}`,
        }),
    type: Joi.string()
        .required("required")
        .label(i18n.t("Type"))
        .messages({
            'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
            'string.empty': `{#label} ${i18n.t("validationErrors.isEmpty")}`,
        }),
    guests: Joi.number()
        .positive()
        .required("required")
        .label(i18n.t("Guests"))
        .messages({
            'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}`,
            'string.empty': `{#label} ${i18n.t("validationErrors.isEmpty")}`,
        }),
    phone: Joi.string()
        // .required()
        .label(i18n.t("Phone"))
        .messages({ 'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}` })
        .custom((value, helper) => {
            const parsedPhone = parsePhoneNumber(value, { extract: true });
            if (!parsedPhone || !parsedPhone.isValid())
                return helper.message(i18n.t("validationErrors.isValidPhone"));
            return true
        }),
    canSend: Joi.bool()
    // .label(i18n.t("Phone"))
    // .messages({ 'any.required': `{#label} ${i18n.t("validationErrors.isRequired")}` })
    // .custom((value, helper) => {
    //     const parsedPhone = parsePhoneNumber(value, { extract: true });
    //     if (!parsedPhone || !parsedPhone.isValid())
    //         return helper.message(i18n.t("validationErrors.isValidPhone"));
    //     return true
    // }),
})