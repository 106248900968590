import { AuthContext } from "context";
import moment from "moment";
import 'moment/locale/ar';
import { useContext } from "react";

export default function FormatDate({ date = new Date(), format }) {
    const { authState: { language } } = useContext(AuthContext);
    moment.locale(language);
    return (
        <span>
            {moment(date).format(format)}
        </span>
    )
}