import * as React from 'react';
import PropTypes from "prop-types";
import { Divider, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

function ConfirmationDialog({
    // confirmDialogStatus,
    title,
    btnText,
    content,
    confirmAction,
    pendingDelete,
    LoadingButtonProps,
    children,
    ...props
}) {
    const { t } = useTranslation()
    const theme = useTheme()
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <LoadingButton
                color="error"
                variant="contained"
                disableElevation
                onClick={handleClickOpen}
                loading={pendingDelete}
                {...LoadingButtonProps}
                {...props}
            >
                {btnText ? btnText : children}
            </LoadingButton>

            <Dialog
                dir={theme.direction}
                open={open}
                onClose={(e) => e.preventDefault()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button sx={{
                        textTransform: "none",
                    }} variant='contained'  onClick={handleClose}>{t("Close")}</Button>
                    <Button sx={{
                        textTransform: "none",
                        mx: '4px'
                    }} variant='contained'  onClick={() => {
                        confirmAction()
                        handleClose()
                    }} autoFocus>
                        {t("Confirm")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
ConfirmationDialog.propTypes = {
    // confirmDialogStatus: propTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    confirmAction: PropTypes.func.isRequired,
    LoadingButtonProps: PropTypes.object,
    btnText: PropTypes.string,
    pendingDelete: PropTypes.bool,
};

export default ConfirmationDialog;