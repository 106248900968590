import { Stack, Typography } from "@mui/material";
import { Date, Time, GuestsNumber } from 'assets/SmallIcons';
import { Icon } from "modules/_shared";
import PropTypes from 'prop-types';
import { get, keys } from "lodash";

const detailTypes = {
    date: Date,
    time: Time,
    guestsNumber: GuestsNumber
};

const AttendanceDetail = ({ type, detail, ...rest }) => {
    if (!type)
        return null

    return (
        <Stack direction="row" {...rest}>
            <Icon svgSource={get(detailTypes, type)} />
            <Typography
                sx={{
                    marginInlineStart: 10,
                    font: "normal 16px/25px Rabar",
                    letterSpacing: 0,
                    color: "zenithBlue",
                }}
            >
                {detail}
            </Typography>
        </Stack>
    )
}

AttendanceDetail.propTypes = {
    type: PropTypes.oneOf(keys(detailTypes)).isRequired,
    detail: PropTypes.string.isRequired
}

export default AttendanceDetail;