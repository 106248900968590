import React from 'react';
// import MapItTextField from "./MapItTextField";
import { includes } from 'lodash';
import { TextField } from '@mui/material';

const MapItLocalizedNumberTextField = ({ type, min, name, value, handleChange, ...props }) => {

  const numberInputAllowableSpecialCharacters = ['e', '+', '-'];

  const keyDownHandlerForNumberInputs = keyDownEvent => {
    if (includes(numberInputAllowableSpecialCharacters, keyDownEvent.key))
      keyDownEvent.preventDefault();
    if (keyDownEvent.key === "ArrowUp") {
      increment()
    }

    if (keyDownEvent.key === "ArrowDown" && (min && value ? Number(value) > min : Number(value) > 0)) {
      decrement()
    }
  }

  const increment = _ => {
    handleChange && (value || value === "") && handleChange({ target: { name, value: Number(value) + 1 } });
  }

  const decrement = _ => {
    handleChange && value && handleChange({ target: { name: name, value: Number(value) - 1 } });
  }

  const parseHindiAndPersianNumbers = str => {
    return str.replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (d) {
      return d.charCodeAt(0) - 1632;
    }).replace(/[۰۱۲۳۴۵۶۷۸۹]/g, function (d) {
      return d.charCodeAt(0) - 1776;
    });
  }

  const changeHandlerForNumberInputs = changeEvent => {
    let { value } = changeEvent.target;
    value = parseHindiAndPersianNumbers(value);
    // prevent zero at beginning, check for min value, prevent NaN
    if (value.slice(0, 1) === "0" || (min && Number(value) < min) || isNaN(Number(value))) return changeEvent.preventDefault()

    const newEvent = { target: { name: changeEvent.target.name, value } }
    handleChange && handleChange(newEvent);
  }

  return (
    <TextField
      fullWidth
      {...props}
      name={name}
      value={value}
      // if type is set to number, hindi numers will be blocked
      inputProps={{ onKeyDown: keyDownHandlerForNumberInputs }}
      onChange={changeHandlerForNumberInputs}
      size="small"
      sx={{
        'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
          'WebkitAppearance': 'none',
          margin: 0,
        },
        'input[type=number]': {
          'MozAppearance': 'textfield',
        }
      }}
    />
  )
}

export default MapItLocalizedNumberTextField;