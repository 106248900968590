import React from 'react';
import PhoneInput from 'react-phone-number-input'
import { PropTypes } from "prop-types";
import MapitPhoneInputComponent from './MapitPhoneInputComponent';
import 'react-phone-number-input/style.css'
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { includes, isEmpty } from 'lodash';

const MapitPhoneInput = ({ countries, defaultCountry, value, handleChange, error, children, containerProps, ...props }) => {

    const { t } = useTranslation();

    const handleChangeInside = value => {
        const e = { target: { name: props.name, value } }
        handleChange(e)
    }

    return (
        <Box >
            <Box dir="ltr">
                <PhoneInput
                    // dir="ltr"
                    placeholder="Enter phone number"
                    value={value}
                    onChange={handleChangeInside}
                    defaultCountry={defaultCountry}
                    countries={countries}
                    addInternationalOption={false}
                    international={true}
                    countryCallingCodeEditable={false}
                    inputComponent={MapitPhoneInputComponent}
                    flags={{
                        SA: (props) => <img alt="sa" width={"100%"} src={"/static/images/SA.svg"} />,
                        EG: (props) => <img alt="eg" width={"100%"} src={"/static/images/EG.svg"} />
                    }}
                    {...props}
                />
            </Box>
            {
                error &&
                <Typography
                    sx={{
                        fontWeight: "normal",
                        fontSize: "12px",
                        letterSpacing: "-0.05px",
                        fontFamily: "Dubai,AraHamah1964,Arial",
                        lineHeight: 1.66,
                        marginTop: "4px",
                        marginLeft: "14px",
                        marginBottom: 0,
                        marginRight: "14px",
                        color: "#d32f2f"
                    }}
                    color='text.danger'
                >
                    {t(error)}
                </Typography>
            }
        </Box>
    )
}

MapitPhoneInput.propTypes = {
    countries: PropTypes.array.isRequired,
    defaultCountry: function (props, propName, componentName) {
        if (
            props['countries'] &&
            !isEmpty(props['countries']) &&
            !includes(props['countries'], props[propName])
        ) {
            return new Error(
                'Invalid prop `' + propName + '` supplied to' +
                ' `' + componentName + '`. Expected one of: ' + JSON.stringify(props['countries'])
            );
        }
    },
    value: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    error: PropTypes.string,
    containerProps: PropTypes.object,
    props: PropTypes.object
};

MapitPhoneInput.defaultProps = {
    countries: process.env.REACT_APP_ENV === "production" ? ["SA"] : ["SA", "EG"],
    defaultCountry: "SA",
};

export default MapitPhoneInput;