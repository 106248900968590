import { Stack } from "@mui/material";
import ContactDetail from "./ContactDetail";

const ContactDetails = ({ ...rest }) => {

    return (
        <Stack {...rest}>
            <Stack spacing={7}>
                <ContactDetail type="address" detail="شارع الأمير محمد بن عبدالعزيز(التحلية) - العليا" />
                <ContactDetail type="phone" detail="0550325566" />
            </Stack>
        </Stack>
    )
}

export default ContactDetails;