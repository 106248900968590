import React from "react";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Landing = () => {

    const navigate = useNavigate()
    const {t} = useTranslation()

    return (
        <Box sx={{ display: "flex", justifyContent: 'center' }}>
            <Button
                onClick={() => {
                    navigate("/auth/admin")
                }}
                color="white"
                variant="outlined"
                size="large"
                sx={{
                    width: 150,
                    height: 55,
                    borderRadius: 27.5,
                    textTransform: "none",
                    font: "normal 16px/23px Rabar",
                    '&:hover': {
                        backgroundColor: 'autumnOrange',
                        color: 'white',
                    },
                }}>
                {t("Admin")}
            </Button>
            <Button
                onClick={() => {
                    navigate("/auth/host")
                }}
                color="white"
                variant="outlined"
                size="large"
                sx={{
                    marginInlineStart: 10,
                    width: 150,
                    height: 55,
                    borderRadius: 27.5,
                    textTransform: "none",
                    font: "normal 16px/23px Rabar",
                    '&:hover': {
                        backgroundColor: 'autumnOrange',
                        color: 'white',
                    },
                }}>
                {t("Host")}
            </Button>
        </Box>
    )
}

export default Landing;