import { Box, Container, Paper, styled, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import { FooterBlueArabic, LogoBlueArabic, LogoBlueEnglish } from "assets/images";
import { CssBaseline } from "@mui/material";
import { EnglishMenu, ArabicMenu, MenuImages } from "assets/menus";
// import { I18N_LANGUAGES } from "constants";
import { AuthContext } from "context";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
// import DrawerList from "./components/DrawerList"
import "./style.css"
import MenuAppBar from "modules/_shared/UI/MenuAppBar";



const TabItem = styled(Tab)(() => ({
    color: 'white',
    textTransform: 'none',
    minWidth: 50,
    paddingLeft: 10,
    paddingRight: 10
}));
export default function Menu() {
    const { t } = useTranslation();
    const [selectedCategory, setSelectedCategory] = useState("appetizer");
    const navbarTabsRef = useRef(null)
    const stickyRef = useRef(null)
    const loackRef = useRef(false)
    const sections = useRef(null)

    const { authState: { language } } = useContext(AuthContext);
    const Menu = useMemo(() => {
        if (language === "en-US") return EnglishMenu
        return ArabicMenu
    }, [language])


    const foodCategories = [
        { text: 'Appetizer', value: 'appetizer' },
        { text: 'Pasta', value: 'pasta' },
        { text: 'Risotto', value: 'risotto' },
        { text: "Sicilee's dishes", value: 'scallop' },
        { text: 'Pizza', value: 'pizza' },
        { text: 'Salad', value: 'salad' },
        { text: 'Dessert', value: 'dessert' },
        { text: 'Drinks', value: 'drinks' }
    ]

    const handleChange = (event, newValue) => {
        loackRef.current = true
        setSelectedCategory(newValue);
        setTimeout(() => {
            // document.getElementById(newValue).scrollIntoView({
            //     behavior: 'smooth', block: "start"
            // });
            let heightSpace = navbarTabsRef.current.classList.contains("sticky") ? 50 : 100
            window.scrollTo({
                behavior: 'smooth',
                top:
                    document.getElementById(newValue).getBoundingClientRect().top -
                    document.body.getBoundingClientRect().top -
                    heightSpace,
            })
            setTimeout(() => {
                loackRef.current = false
            }, 1000)
        }, 420)
    };
    function stickyNavbarTabsHandler() {
        if (window.pageYOffset >= stickyRef.current) {
            navbarTabsRef.current.classList.add("sticky")
        } else {
            navbarTabsRef.current.classList.remove("sticky");
        }
    }
    function activeTabIntersection() {
        if (!loackRef.current) {
            let current = "";
            const arr = [...sections.current]
            arr.forEach((section) => {
                const sectionTop = section.offsetTop;
                const sectionHeight = section.clientHeight;
                if (window.pageYOffset >= sectionTop - sectionHeight / 3) {
                    current = section.getAttribute("id");
                }
                if (!!current) {
                    setSelectedCategory(current)
                }
            });
        }
    }
    useEffect(() => {
        navbarTabsRef.current = document.getElementById("navbar-tabs")
        stickyRef.current = navbarTabsRef.current.offsetTop;
        sections.current = document.getElementsByClassName("food-category");
        window.onscroll = function () {
            stickyNavbarTabsHandler()
            activeTabIntersection()
        }
    }, [])


    const tabsRef = useRef(null)
    useEffect(() => {
        let elem = tabsRef.current.getElementsByClassName("MuiTabs-scroller")[0]
        let activeTab = tabsRef.current.getElementsByClassName("Mui-selected")[0]
        if (elem && activeTab) {
            setTimeout(() => {
                let left = activeTab.offsetLeft
                let width = elem.offsetWidth;
                let diff = left - width / 2
                elem.scrollTo({ left: diff, top: 0, behavior: 'smooth' });
            }, 500);
        }

    }, [selectedCategory])


    // const handleLanguageClick = _ => {
    //     const language = i18n.language
    //     const languageToSet = language === I18N_LANGUAGES.ar ? I18N_LANGUAGES.en : I18N_LANGUAGES.ar
    //     i18n.changeLanguage(languageToSet);
    //     setCurrentLanguage(languageToSet)
    // };

    const handleDrawerOpen = () => { }

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        <Container maxWidth="sm" sx={{ px: matches && 0 }}>
            <CssBaseline />
            <MenuAppBar handleDrawerOpen={handleDrawerOpen} />
            <Box display="flex" flexDirection="column">
                {/* <Box sx={{
                backgroundImage: `url(${LogoBlueArabic})`,
                width: '100%',
                height: `${matches ? '40vh' : '93.7vh'}`,
                backgroundSize: '100% 100%'
            }}> */}
                <div>
                    <img
                        alt="sicilee logo"
                        style={{
                            width: '100%',
                            height: 'auto',
                            display: "block"
                        }}
                        onLoad={() => {
                            stickyRef.current = navbarTabsRef.current.offsetTop
                        }}
                        src={language === "en-US" ? LogoBlueEnglish : LogoBlueArabic}
                    />
                    {/* <Button
                        color="white"
                        disableRipple
                        py={0}
                        sx={{ textTransform: "none", position: "absolute", top: 10, right: 10 }}
                        onClick={handleLanguageClick}
                    >
                        {t("العربية")}
                    </Button> */}
                </div>
                {/* </Box> */}
                <Box id="navbar-tabs" bgcolor="zenithBlue" px={12} borderTop="1px solid #C7C7CC" display="flex" justifyContent="center">
                    {/* <DrawerList foodCategories={foodCategories} handleChange={handleChange} selectedCategory={selectedCategory} /> */}
                    <Tabs
                        ref={tabsRef}
                        indicatorColor="secondary"
                        textColor="secondary"
                        value={selectedCategory}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        {foodCategories.map(({ text, value }) => {
                            return (<TabItem key={text} label={t(text)} value={value} />)
                        })}
                    </Tabs>
                </Box>
                <Paper
                    elevation={matches ? 16 : 0}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        mt: matches ? 20 : 0,
                        borderTopRightRadius: matches ? '25px' : 0,
                        borderTopLeftRadius: matches ? '25px' : 0
                    }}>
                    <img
                        className="food-category"
                        id="appetizer"
                        alt="Appetizer text"
                        style={{
                            width: '100%',
                            borderTopRightRadius: matches ? '25px' : 0,
                            borderTopLeftRadius: matches ? '25px' : 0
                        }}
                        src={Menu.AppetizerText}
                    />
                    <img
                        alt="appetizer view"
                        style={{
                            width: '100%',
                        }}
                        src={MenuImages.AppetizerImg}
                    />
                    <Box display="flex" flexDirection="column" className="food-category" id="pasta">
                        <img
                            alt="Pasta menu"
                            style={{
                                width: '100%',
                            }}
                            src={Menu.PastaText}
                        />
                        <img
                            alt="pasta view"
                            style={{
                                width: '100%',
                            }}
                            src={MenuImages.PastaImg}
                        />
                    </Box>
                    <Box display="flex" flexDirection="column" className="food-category" id="risotto">
                        <img
                            alt="Risotto menu"
                            style={{
                                width: '100%',
                            }}
                            src={Menu.RisottoText}
                        />
                    </Box>
                    <Box display="flex" flexDirection="column" className="food-category" id="scallop">
                        <img
                            alt="scallop menu"
                            style={{
                                width: '100%',
                            }}
                            src={Menu.ScallopText}
                        />
                        {/* <img
                            alt="scallop view"
                            style={{
                                width: '100%',
                            }}
                            src={MenuImages.ScallopImg}
                        /> */}
                    </Box>
                    <Box display="flex" flexDirection="column" className="food-category" id="pizza">
                        <img
                            alt="pizza text"
                            style={{
                                width: '100%',
                            }}
                            src={Menu.PizzaText}
                        />
                        <img
                            alt="pizza view"
                            style={{
                                width: '100%',
                            }}
                            src={MenuImages.PizzaImg}
                        />
                    </Box>
                    <img
                        className="food-category"
                        id="salad"
                        alt="Salad menu"
                        style={{
                            width: '100%',
                        }}
                        src={Menu.SaladText}
                    />
                    <Box display="flex" flexDirection="column" className="food-category" id="dessert">
                        <img
                            alt="Dessert menu"
                            style={{
                                width: '100%',
                            }}
                            src={Menu.DessertText}
                        />
                        <img
                            alt="Dessert view"
                            style={{
                                width: '100%',
                            }}
                            src={MenuImages.DessertImg}
                        />
                    </Box>
                    <Box display="flex" flexDirection="column" className="food-category" id="drinks">
                        <img
                            alt="Drinks menu"
                            style={{
                                width: '100%',
                            }}
                            src={Menu.DrinksText}
                        />
                        <img
                            alt="Drinks view"
                            style={{
                                width: '100%',
                            }}
                            src={MenuImages.DrinksImg}
                        />
                    </Box>
                    <img alt="footer"
                        src={FooterBlueArabic}
                        style={{
                            width: '100%',
                        }} />
                </Paper>
            </Box>
        </Container>
    )
}