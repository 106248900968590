import AdminDashboard from "modules/admin/AdminDashboard"
import HostDashboard from "modules/host/HostDashboard"
import AuthLayout, { Landing, Login } from "modules/auth";
import { HostLandingPage, CheckInPage } from "modules/host/views";
import {
    MainLayout,
    ErrorNotFound,
    ErrorServerDown,
    ErrorUnauthorized
} from "modules/Errors";
import {
    Hosts,
    Invitations
} from "modules/admin/views";
import { Navigate } from "react-router-dom";
import Invitation, { INVITATION_DETAILS_TYPES } from "modules/guest";
import Menu from "modules/public/Menu";
import Rating from "modules/public/Rating";
import RedirectToFoodee from "./RedirectToFoodee";

const Dashboard = ({ role }) => {
    if (role === "admin") {
        return <Navigate to="/admin" />
    } else if (role === "host") {
        return <Navigate to="/host" />
    }
}

const generateRoutes = ({ role, isAuth }) => {
    return ([
        {
            path: "/",
            element: <Navigate to="menu" />,
        },
        {
            path: "invitations",
            element: !isAuth ? <AuthLayout /> : <Dashboard role={role} />,
            children: [
                { path: "", element: <Landing /> },
                { path: "*", element: <Navigate to="/invitations" /> },
            ]
        },
        {
            path: "auth",
            element: !isAuth ? <AuthLayout /> : <Dashboard role={role} />,
            children: [
                { path: "", element: <Navigate to="/auth/admin" /> },
                { path: "admin", element: <Login login_role="admin" /> },
                { path: "host", element: <Login login_role="host" /> },
                { path: "*", element: <Navigate to="/errors/404" /> },
            ],
        },
        {
            path: "admin",
            element: isAuth && role === "admin" ? <AdminDashboard /> : <Navigate to="/auth/admin" />,
            children: [
                { path: "", element: <Invitations /> },
                { path: "hosts", element: <Hosts /> },
                { path: "*", element: <Navigate to="/errors/404" /> },
            ],
        },
        {
            path: "host",
            element: isAuth && role === "host" ? <HostDashboard /> : <Navigate to="/auth/host" />,
            children: [
                { path: "", element: <HostLandingPage /> },
                { path: "checkIn", element: <CheckInPage /> },
                { path: "*", element: <Navigate to="/errors/404" /> },
            ],
        },
        {
            path: "guest/:invitationId",
            element: <Invitation type={INVITATION_DETAILS_TYPES.fullDetails} />,
        },
        {
            path: "banner/:invitationId",
            element: <Invitation type={INVITATION_DETAILS_TYPES.banner} />,
        },
        {
            path: "menu",
            element: <RedirectToFoodee />,
            // element: <Menu />,
        },
        {
            path: "rating",
            element: <Rating />,
        },
        {
            path: "*",
            element: <RedirectToFoodee />,
            // element: <MainLayout />,
            // children: [
            //     { path: "404", element: <ErrorNotFound /> },
            //     { path: "401", element: <ErrorUnauthorized /> },
            //     { path: "500", element: <ErrorServerDown /> },
            //     { path: "", element: <Navigate to="404" /> },
            //     { path: "*", element: <Navigate to="404" /> },
            // ]
        }
    ])

}

export default generateRoutes;