import { Icon } from "@mui/material";
import PropTypes from 'prop-types';

const CustomIcon = ({ svgSource, height = 25, width = 25, ...rest }) => (
    <img alt="img" src={svgSource} height={height} width={width} {...rest} />
)

Icon.propTypes = {
    svgSource: PropTypes.object.isRequired,
};

export default CustomIcon;