import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { t } from 'i18next';
import PersonIcon from '@mui/icons-material/Person';
import FeedIcon from '@mui/icons-material/Feed';
import { Link, matchPath, useLocation } from 'react-router-dom';

const drawerWidth = 240;

export default function ClippedDrawer({ open, handleDrawerOpen }) {
  const theme = useTheme();
  const location = useLocation();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const active = (route) => !!matchPath({ path: route, end: true }, location.pathname)

  return (
    <Drawer
      variant={!matches ? "permanent" : "temporary"}
      open={open}
      onClose={handleDrawerOpen}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <List>
            <ListItem disablePadding> 
              <ListItemButton onClick={handleDrawerOpen} selected={active("/admin")} component={Link} to="/admin" >
                <ListItemIcon>
                  <FeedIcon />
                </ListItemIcon>
                <ListItemText primary={t("Invitations")} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding> 
              <ListItemButton onClick={handleDrawerOpen} selected={active("/admin/hosts")} component={Link} to="/admin/hosts" >
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary={t("Hosts")} />
              </ListItemButton>
            </ListItem>
        </List>
      </Box>
    </Drawer>
  );
}
