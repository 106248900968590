import React from "react";
import { CssBaseline } from "@mui/material";
import { Box } from "@mui/system";
import AppBar from "modules/_shared/UI/AppBar"

import { Outlet } from "react-router-dom";
import { LogoWhite } from "assets/images";
import { Footer } from "modules/_shared";
import { useNavigate } from "react-router-dom";

export default function MainLayout({ role, type }) {
  
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar handleDrawerOpen={handleDrawerOpen} />
      <Box component="main" sx={{ backgroundColor: "zenithBlue", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "stretch", flexGrow: 1, height: "100vh", overflow: "hidden" }}>
        <Box display={"flex"} justifyContent={"center"} pt={20}>
          <img
            alt="sicilee logo"
            style={{
              width: 250,
              height: 250,
              cursor: "pointer"
            }}
            src={LogoWhite}
            onClick={_ => navigate("/")}
          />
        </Box>
        <Box flexGrow={1}>
          <Outlet />
        </Box>
        <Footer color="white" />
      </Box>
    </Box>
  )
}