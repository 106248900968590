export const $APP_NAME = "Sicilee";
export const $__DEV__ = false // process.env.REACT_APP_ENV === "development";
export const $BASE_URL = process.env.REACT_APP_SERVER_URL;
export const $cloudinaryURL = 'https://res.cloudinary.com/dhisioywv/image/upload/v1652345250'

export const API_LOADING_STATUSES = {
    idle: "idle",
    loading: "loading",
    succeeded: "succeeded",
    failed: "failed",
}

export const I18N_LANGUAGES = {
    ar: "ar-SA",
    en: "en-US"
}

export const INVITATION_STATUS = {
    created: "CREATED",
    expired: "EXPIRED",
    checkedIn:"CHECKED_IN"
}

export const DATE_FORMATS = {
    dayMonthYear: "DD - MM - YYYY",
    dayMonthYearTime: "DD - MM - YYYY  hh:mm A",
    weekdayDayMonthNameYear: "dddd D MMMM YYYY"
}

export const INVITATION_TYPES = {
    opening: "OPENING_INVITATION",
    regular: "NORMAL_INVITATION"
}

export const GENDERS = {
    male: "MALE",
    female: "FEMALE"
}